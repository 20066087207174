/** @format */

import React, { PureComponent } from "react";
import axios from "axios";

// import Dropdown from "react-dropdown";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns/esm";
import "../css/requestForm.css";
import { withRouter } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import fitness from "../assets/img/banners/Fitness-Banner.jpg";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import { GLOBAL_DOMAIN } from "../actions/types";

class FitnessRequest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      typeModalSelectedValueConfirmed: "",
      genderModalSelectedValueConfirmed: "",
      dateModalSelectedValueConfirmed: "",
      timeModalSelectedValueConfirmed: "",
      trainerNameValue: "",
      detailsValue: "",
    };
  }

  onTypeModalConfirmValue = (value) => {
    this.setState({
      typeModalSelectedValueConfirmed: value,
    });
  };

  onDateModalConfirmValue = (value) => {
    this.setState({
      dateModalSelectedValueConfirmed: value,
    });
  };

  onTimeModalConfirmValue = (value) => {
    // value = value === null ? new Date() : value;

    this.setState({
      timeModalSelectedValueConfirmed: value,
    });
  };

  onGenderModalConfirmValue = (value) => {
    this.setState({
      genderModalSelectedValueConfirmed: value,
    });
  };
  onTrainerTextChange = (text) => {
    this.setState({ trainerNameValue: text.toUpperCase() });
  };

  onDetailsTextChange = (text) => {
    this.setState({ detailsValue: text.toUpperCase() });
  };

  submitRequest = async () => {
    try {
      let subCat = "Other";
      if (this.state.typeModalSelectedValueConfirmed === "YOGA") {
        subCat = "Yoga Instructor";
      } else if (
        this.state.typeModalSelectedValueConfirmed === "PERSONAL TRAINING"
      ) {
        subCat = "Personal Training";
      } else if (this.state.typeModalSelectedValueConfirmed === "PILATES") {
        subCat = "Pilates Instructor";
      } else if (this.state.typeModalSelectedValueConfirmed === "SWIM") {
        subCat = "Swim Lesson";
      } else if (this.state.typeModalSelectedValueConfirmed === "MASSAGE") {
        subCat = "Massage";
      } else if (this.state.typeModalSelectedValueConfirmed === "MASSAGE") {
        subCat = "Massage";
      }

      let preferredDate = this.state.dateModalSelectedValueConfirmed;

      let preferredDateDay = preferredDate.getDate();
      let preferredDateMonth = preferredDate.getMonth() + 1;
      let preferredDateYear = preferredDate.getFullYear();
      let preferredDateString =
        preferredDateYear + "-" + preferredDateMonth + "-" + preferredDateDay;

      const preferredTime = this.state.timeModalSelectedValueConfirmed;
      let preferredHours = preferredTime.getHours();
      let preferredMinutes = preferredTime.getMinutes();
      let AMorPM = "AM";
      if (preferredHours === 0) {
        preferredHours = 12;
      } else if (preferredHours === 12) {
        AMorPM = "PM";
      }
      if (preferredHours > 12) {
        preferredHours -= 12;
        AMorPM = "PM";
      }
      if (preferredMinutes < 10) {
        preferredMinutes = "0" + preferredMinutes;
      }

      let preferredTimeString =
        preferredHours + ":" + preferredMinutes + AMorPM;

      let trainerName = "No Preference";

      if (this.state.trainerNameValue) {
        trainerName = this.state.trainerNameValue;
      }

      let detailsValueString = this.state.detailsValue;

      if (detailsValueString === "") {
        detailsValueString = "N/A";
      }

      // let genderPreference = "No Preference";

      // if (this.state.genderModalSelectedValueConfirmed === "NO PREFERENCE") {
      //   genderPreference = "No Preference";
      // } else if (this.state.genderModalSelectedValueConfirmed === "MALE") {
      //   genderPreference = "Male";
      // } else if (this.state.genderModalSelectedValueConfirmed === "FEMALE") {
      //   genderPreference = "Female";
      // }

      const cat = "Fitness/Wellness";
      const subject = "Fitness Request";
      let jsonObj = JSON.stringify({
        RecordTypeId: "0124A000001NSKn",
        Category__c: cat,
        Sub_Category__c: subCat,
        Preferred_Date__c: preferredDateString,
        Preferred_Time__c: preferredTimeString,
        Preferred_Gender__c:
          this.state.genderModalSelectedValueConfirmed.toUpperCase(),
        Preferred_Trainer__c: trainerName,
        Additional_Details__c: detailsValueString,
        Subject: subject,
        Origin: "Web Portal",
        ContactId: localStorage.getItem("contactId"),
      });

      let response = await axios.get(`${GLOBAL_DOMAIN}/api/request/`, {
        params: {
          json: jsonObj,
          token: localStorage.getItem("token"),
        },
      });

      return { success: response.data.success };
    } catch (err) {
      console.log("Submit Fitness Request err", err);

      return { success: false, error: err };
    }
  };

  reAuthenticate = async () => {
    // If access token comes back expired
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("getSalesforceToken err on login", err);
      return { success: false, error: err };
    }
  };

  attemptSubmitRequest = async () => {
    // Validation Step

    if (
      !this.state.typeModalSelectedValueConfirmed ||
      !this.state.dateModalSelectedValueConfirmed ||
      !this.state.timeModalSelectedValueConfirmed ||
      !this.state.genderModalSelectedValueConfirmed
    ) {
      return;
    }

    let response = await this.submitRequest();

    // if response says 'Session expired or invalid' then attempt to reauthenticate
    // If that fails, user must log in again, if works, then attempt to submit request again

    if (response.success) {
      //Request successful, navigate to Request Confirmation screen

      this.setState({
        typeModalSelectedValueConfirmed: "",
        genderModalSelectedValueConfirmed: "",
        dateModalSelectedValueConfirmed: new Date(),
        timeModalSelectedValueConfirmed: new Date(),
        trainerNameValue: "",
        detailsValue: "",
      });
      this.props.history.push("/requests/submitted");
    } else {
      // Submit request failed: Try to get new Salesforce token

      let reauthResponse = await this.reAuthenticate();

      if (reauthResponse.success) {
        //New Token, add to redux and retry submitting request

        // this.props.addToken(reauthResponse.access_token);
        localStorage.setItem("token", reauthResponse.access_token);

        let responseTwo = await this.submitRequest();

        if (responseTwo.success) {
          // Second try request worked

          this.setState({
            typeModalSelectedValueConfirmed: "",
            genderModalSelectedValueConfirmed: "",
            dateModalSelectedValueConfirmed: new Date(),
            timeModalSelectedValueConfirmed: new Date(),
            trainerNameValue: "",
            detailsValue: "",
          });
          this.props.history.push("/requests/submitted");
        } else {
          // Second try failed, send alert notification
          return;
        }
      }
    }
  };

  render() {
    return (
      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <div className="request_title_div" style={{}}>
          <p className="request_title">FITNESS</p>
        </div>
        <img
          src={fitness}
          style={{
            height: "270px",
            objectFit: "cover",
            width: "100%",
          }}
          alt="fitness-banner"
        ></img>

        <Container>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.attemptSubmitRequest();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <br />
            {/* <p style={{ textAlign: "center", fontSize: 40, fontWeight: 600 }}>
              Fitness
            </p> */}
            <Form.Group className="form_item">
              {/* <Form.Label>Category</Form.Label> */}
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.typeModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onTypeModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              >
                <option value="" style={{ textAlignLast: "center" }}>
                  CATEGORY*
                </option>
                <option style={{ textAlignLast: "center" }}>
                  Personal Training
                </option>
                <option>Pilates</option>
                <option>Swim</option>
                <option>Yoga</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <DatePicker
                selected={this.state.dateModalSelectedValueConfirmed}
                placeholderText="PREFERRED DATE"
                onChange={(e) => this.onDateModalConfirmValue(e)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 90)}
              />
            </Form.Group>
            <Form.Group className="form_item">
              <DatePicker
                selected={
                  this.state.timeModalSelectedValueConfirmed
                    ? this.state.timeModalSelectedValueConfirmed.getTime()
                    : ""
                }
                showTimeSelect
                showTimeSelectOnly
                placeholderText="PREFERRED TIME"
                // minTime={new Date()}
                // maxTime={setHours(setMinutes(new Date(), 30), 23)}
                dateFormat="h:mm aa"
                onChange={(e) => this.onTimeModalConfirmValue(e)}
              />
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.genderModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onGenderModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              >
                <option value="">PREFERRED GENDER</option>
                <option>No Preference</option>
                <option>Male</option>
                <option>Female</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                onChange={(e) => this.onTrainerTextChange(e.target.value)}
                placeholder="PREFERRED TRAINER"
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                  textAlign: "center",
                }}
              />
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                onChange={(e) => this.onDetailsTextChange(e.target.value)}
                placeholder="ADDITIONAL DETAILS"
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                  textAlign: "center",
                }}
              />
            </Form.Group>
            <Button
              variant="dark"
              className="form_submit_button"
              type="submit"
              style={{ backgroundColor: "black" }}
            >
              <p style={{ fontSize: "24px", margin: "0px" }}>SUBMIT</p>
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}

export default withRouter(FitnessRequest);
