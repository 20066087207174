/** @format */

import React, { PureComponent } from "react";
import axios from "axios";
import "react-dropdown/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { withRouter } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import other from "../assets/img/banners/Other-Banner.jpg";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import { GLOBAL_DOMAIN } from "../actions/types";

class OtherRequest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      typeModalSelectedValueConfirmed: "",
      dateModalSelectedValueConfirmed: "", //new Date(),
      timeModalSelectedValueConfirmed: "", //new Date(),
      detailsValue: "",
    };
  }

  onTypeModalConfirmValue = (value) => {
    this.setState({
      typeModalSelectedValueConfirmed: value,
    });
  };

  onDateModalConfirmValue = (value) => {
    this.setState({
      dateModalSelectedValueConfirmed: value,
    });
  };

  onTimeModalConfirmValue = (value) => {
    this.setState({
      timeModalSelectedValueConfirmed: value,
      timeModalSelectedValueConfirmedString: `${this.state.timeModalSelectedValue1}:${this.state.timeModalSelectedValue2} ${this.state.timeModalSelectedValue3}`,
    });
  };

  onDetailsTextChange = (text) => {
    this.setState({ detailsValue: text.toUpperCase() });
  };

  submitRequest = async () => {
    try {
      let preferredDate = this.state.dateModalSelectedValueConfirmed;
      //let preferredDateString = preferredDate.toISOString().substring(0, 10);

      let preferredDateDay = preferredDate.getDate();
      let preferredDateMonth = preferredDate.getMonth() + 1;
      let preferredDateYear = preferredDate.getFullYear();
      let preferredDateString =
        preferredDateYear + "-" + preferredDateMonth + "-" + preferredDateDay;

      const subject = this.state.typeModalSelectedValueConfirmed;
      const preferredTime = this.state.timeModalSelectedValueConfirmed;
      let preferredHours = preferredTime.getHours();
      let preferredMinutes = preferredTime.getMinutes();
      let AMorPM = "AM";
      if (preferredHours === 0) {
        preferredHours = 12;
      } else if (preferredHours === 12) {
        AMorPM = "PM";
      }
      if (preferredHours > 12) {
        preferredHours -= 12;
        AMorPM = "PM";
      }
      if (preferredMinutes < 10) {
        preferredMinutes = "0" + preferredMinutes;
      }

      let preferredTimeString =
        preferredHours + ":" + preferredMinutes + AMorPM;

      let detailsValueString = this.state.detailsValue;

      if (detailsValueString === "") {
        detailsValueString = "N/A";
      }

      let jsonObj = JSON.stringify({
        RecordTypeId: "0124A000001NSKq",
        Subject: subject,
        Preferred_Date__c: preferredDateString,
        Preferred_Time__c: preferredTimeString,
        Additional_Details__c: detailsValueString,
        Origin: "Web Portal",
        ContactId: localStorage.getItem("contactId"),
      });
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/request/`, {
        params: {
          json: jsonObj,
          token: localStorage.getItem("token"),
        },
      });

      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("Submit Home Request err", err);
      return { success: false, error: err };
    }
  };

  reAuthenticate = async () => {
    // If access token comes back expired
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("getSalesforceToken err on login", err);
      return { success: false, error: err };
    }
  };

  attemptSubmitRequest = async () => {
    // Validation Step
    if (
      !this.state.typeModalSelectedValueConfirmed ||
      !this.state.dateModalSelectedValueConfirmed ||
      !this.state.timeModalSelectedValueConfirmed
    ) {
      return;
      //   return Alert.alert(
      //     "Could Not Submit Request",
      //     "Please fill out all the required fields marked with a * before submitting request",
      //     [
      //       {
      //         text: "OK",
      //         onPress: () => console.log("OK Pressed on validation step")
      //       }
      //     ]
      //   );
    }

    let response = await this.submitRequest();

    // if response says 'Session expired or invalid' then attempt to reauthenticate
    // If that fails, user must log in again, if works, then attempt to submit request again

    if (response.success) {
      //Request successful, navigate to Request Confirmation screen

      this.setState({
        typeModalSelectedValueConfirmed: "",
        dateModalSelectedValueConfirmed: new Date(),
        timeModalSelectedValueConfirmed: new Date(),
        detailsValue: "",
      });
      this.props.history.push("/requests/submitted");

      //   this.props.navigation.navigate("MakeRequestConfirmation", {
      //     barStyle: "light-content"
      //   });
    } else {
      // Submit request failed: Try to get new Salesforce token

      let reauthResponse = await this.reAuthenticate();

      if (reauthResponse.success) {
        //New Token, add to redux and retry submitting request
        localStorage.setItem("token", reauthResponse.access_token);

        let responseTwo = await this.submitRequest();

        if (responseTwo.success) {
          // Second try request worked

          this.setState({
            typeModalSelectedValueConfirmed: "",
            dateModalSelectedValueConfirmed: new Date(),
            timeModalSelectedValueConfirmed: new Date(),
            detailsValue: "",
          });

          this.props.history.push("/requests/submitted");

          //   this.props.navigation.navigate("MakeRequestConfirmation", {
          //     barStyle: "light-content"
          //   });
        } else {
          // Second try failed, send alert notification
          return;
          //   return Alert.alert(
          //     "Error Submitting Request",
          //     "Sorry, we didn't receive the request. Please try again in a couple of minutes.",
          //     [
          //       {
          //         text: "OK",
          //         onPress: () =>
          //           console.log("OK Pressed on Issue getting Salesforce token")
          //       }
          //     ]
          //   );
        }
      }
    }
  };
  render() {
    return (
      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <div className="request_title_div">
          <p className="request_title">OTHER</p>
        </div>
        <img
          src={other}
          style={{
            height: "270px",
            objectFit: "cover",
            width: "100%",
          }}
          alt="other-banner"
        ></img>
        <Container>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.attemptSubmitRequest();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <p style={{ textAlign: "center", fontSize: 40, fontWeight: 600 }}>
              Other
            </p> */}
            <br />
            <Form.Group className="form_item">
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.typeModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onTypeModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              >
                <option value="">CATEGORY*</option>
                <option>Flowers</option>
                <option>Gifting</option>
                <option>Event Planning</option>
                <option>Travel</option>
                <option>Dry Cleaning</option>
                <option>Pet Services</option>
                <option>Wishful Thinking</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              {/* <Form.Label>Preferred Date</Form.Label>
                <br /> */}
              <DatePicker
                selected={this.state.dateModalSelectedValueConfirmed}
                onChange={(e) => this.onDateModalConfirmValue(e)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 90)}
                placeholderText="PREFERRED DATE"
              />
            </Form.Group>
            <Form.Group className="form_item">
              {/* <Form.Label>Preferred Time</Form.Label>
                <br /> */}
              <DatePicker
                selected={
                  this.state.timeModalSelectedValueConfirmed
                    ? this.state.timeModalSelectedValueConfirmed.getTime()
                    : ""
                }
                showTimeSelect
                showTimeSelectOnly
                // minTime={new Date()}
                // maxTime={setHours(setMinutes(new Date(), 30), 23)}
                dateFormat="h:mm aa"
                onChange={(e) => this.onTimeModalConfirmValue(e)}
                placeholderText="PREFERRED TIME"
              ></DatePicker>
            </Form.Group>
            <Form.Group className="form_item">
              {/* <Form.Label>Additional Details</Form.Label> */}
              <Form.Control
                onChange={(e) => this.onDetailsTextChange(e.target.value)}
                placeholder="ADDITIONAL DETAILS"
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                  textAlign: "center",
                }}
              />
            </Form.Group>
            <Button
              variant="dark"
              className="form_submit_button"
              type="submit"
              style={{ backgroundColor: "black" }}
            >
              <p style={{ fontSize: "24px", margin: "0px" }}>SUBMIT</p>
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}

export default withRouter(OtherRequest);
