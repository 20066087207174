import React, { PureComponent } from "react";
import "../css/promoStyle.css";

import left_arrow from "../assets/buttons/left-arrow.png";
import right_arrow from "../assets/buttons/right-arrow.png";

class Promos extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentSlide: 1,
    };
  }

  changeSlide = (change) => {
    let slideNum = this.state.currentSlide + change;
    if (slideNum > 3) {
      slideNum = slideNum % 3;
    }
    if (slideNum < 1) {
      slideNum = 3;
    }
    this.setState({ currentSlide: slideNum });
  };
  render() {
    const region = localStorage.getItem("region");
    console.log("REGION " + region)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "70px",
          marginBottom: "30px",
        }}
      >
        {region !== "Florida" &&
        <div
          className="arrow"
          onClick={() => {
            this.changeSlide(-1);
          }}
        >
          <img src={left_arrow} className="arrow_img" alt="left_arrow" />
        </div>
  }
        {region === "Florida" ?
        <img
        className="promo-image"
        src={`https://livunltd.com/wp-content/uploads/promos/florida/promo-${this.state.currentSlide}.png`}
        alt="Promo"
        />
        :
        <img
          className="promo-image"
          src={`https://livunltd.com/wp-content/uploads/promos/promo-${this.state.currentSlide}.png`}
          alt="Promo"
        />
  }
  {region !== "Florida" &&
        <div
          className="arrow"
          onClick={() => {
            this.changeSlide(1);
          }}
        >
          <img src={right_arrow} className="arrow_img" alt="right_arrow" />
        </div>
  }
      </div>
    );
  }
}

export default Promos;
