import React, { PureComponent } from "react";
import { Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import login_logo from "../assets/logo/LIV_WhiteLogo.svg";
import black_logo from "../assets/logo/LIV_BlackLogo.svg";
import account_icon from "../assets/buttons/profileicon.png";
import proximanova_bold from "../assets/fonts/Proxima_Nova_Bold.otf";
import instagram from "../assets/buttons/instagram.png";
import linkedin from "../assets/buttons/linkedin.png";
import facebook from "../assets/buttons/facebook.png";
import "../css/style.css";
import "../css/submitted.css";

import {
  Calendar,
  History,
  Login,
  Promos,
  Requests,
  SignUp,
  TicketsRequest,
  RequestSubmitted,
  FitnessRequest,
  HomeRequest,
  OtherRequest,
  ReservationRequest,
  Account,
  Privacy,
} from "./index";

import { Navbar, Nav, Image } from "react-bootstrap";

import {
  onASNameChange,
  onASClearFields,
} from "../reducers/AccountSetupReducer";
import Terms from "./Terms";

class Root extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
    };
  }

  UNSAFE_componentWillMount() {
    let config = {
      apiKey: "AIzaSyCxFTT6FdezPbSybsd0ElOBItvENEwgwls",
      authDomain: "liv-test-4f9fa.firebaseapp.com",
      databaseURL: "https://liv-test-4f9fa.firebaseio.com",
      projectId: "liv-test-4f9fa",
      storageBucket: "liv-test-4f9fa.appspot.com",
      messagingSenderId: "206834849048",
    };

    firebase.initializeApp(config);

    const contactId = localStorage.getItem("contactId");
    const accountId = localStorage.getItem("accountId");
    const region = localStorage.getItem("region");
    if (contactId !== "undefined") {
      this.props.onASNameChange(null, accountId, contactId, region);
    }
  }

  render() {
    const expand = window.innerWidth > 1300;
    const spacer = expand ? "50px" : "25px";
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!this.props.isLoggedIn ? (
          <div
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center"
          // }}
          >
            <div
              style={{
                height: "40vh",
                width: "100%",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={login_logo} fluid />
            </div>
            <Switch>
              {/* <Route exact path="/" component={Login} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/sign-up">
                {!this.props.signedUp ? (
                  (localStorage.removeItem("signedUp"), (<SignUp />))
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="center">
                      PLEASE CHECK YOUR INBOX AND VERIFY YOUR EMAIL.
                    </p>
                    <Link to="/login" className="button">
                      LOGIN
                    </Link>
                  </div>
                )}
              </Route>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route path="/" component={Login} />
            </Switch>
          </div>
        ) : (
          <div>
            <Navbar
              expand="lg"
              className="bs-navbar-collapse"
              style={{
                top: "54px",
                justifyContent: "space-between",
                // margin: "0px 76px",
                alignItems: "center",
              }}
            >
              <Navbar.Brand
                as={Link}
                to="/requests"
                onClick={() => this.setState({ account: false })}
              >
                <Image
                  style={{
                    height: "81px",
                  }}
                  src={black_logo}
                ></Image>
              </Navbar.Brand>
              <div style={{ paddingTop: "8px" }}>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto" activeKey="/requests">
                    <Nav.Item eventkey="/requests">
                      <Nav.Link
                        onClick={() => this.setState({ account: false })}
                        as={Link}
                        to="/requests"
                        style={{
                          fontSize: "21px",
                          color: "black",
                          // color: "#D8D8D8",
                          fontFamily: proximanova_bold,
                        }}
                      >
                        {expand ? `MAKE A REQUEST` : "REQUEST"}
                      </Nav.Link>
                    </Nav.Item>
                    <span style={{ width: spacer }} />
                    <Nav.Item eventkey="/promos">
                      <Nav.Link
                        onClick={() => this.setState({ account: false })}
                        as={Link}
                        to="/promos"
                        style={{
                          fontSize: "21px",
                          color: "black",

                          // color: "#D8D8D8",
                          fontFamily: proximanova_bold,
                        }}
                      >
                        PROMOS
                      </Nav.Link>
                    </Nav.Item>
                    <span style={{ width: spacer }} />

                    <Nav.Item eventkey="/history">
                      <Nav.Link
                        onClick={() => this.setState({ account: false })}
                        as={Link}
                        to="/history"
                        style={{
                          fontSize: "21px",
                          color: "black",

                          // color: "#D8D8D8",
                          fontFamily: proximanova_bold,
                        }}
                      >
                        HISTORY
                      </Nav.Link>
                    </Nav.Item>
                    <span style={{ width: spacer }} />
                    <Nav.Item eventkey="/calendar">
                      <div className={"navbar_item"}>
                        <Nav.Link
                          onClick={() => this.setState({ account: false })}
                          as={Link}
                          eventKey="/calendar"
                          to="/calendar"
                          style={{
                            fontSize: "21px",
                            color: "black",

                            // color: "#D8D8D8",
                            fontFamily: proximanova_bold,
                          }}
                        >
                          CALENDAR
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                    <span style={{ width: spacer }} />
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "white",
                      }}
                      className="account"
                      onClick={() => {
                        let account = !this.state.account;
                        this.setState({ account });
                      }}
                    >
                      <img
                        src={account_icon}
                        style={{ transform: "scale(0.9)" }}
                        alt="account"
                      ></img>
                    </button>
                  </Nav>
                </Navbar.Collapse>
              </div>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => this.setState({ account: false })}
              />
            </Navbar>

            <div
              className="account_toggle"
              style={{
                display: !this.state.account && "none",
              }}
              onClick={() => {
                let account = true;
                this.setState({ account });
              }}
            >
              <Account onASClearFields={this.props.onASClearFields} />
            </div>
            <div
              onClick={() => {
                if (this.state.account) {
                  let account = !this.state.account;
                  this.setState({ account });
                }
              }}
              style={{ flex: 1 }}
            >
              {!this.props.isLoggedIn ? (
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-up">
                    {!this.props.signedUp ? (
                      (localStorage.removeItem("signedUp"), (<SignUp />))
                    ) : (
                      <div>
                        Please check your inbox and verify your email. Then{" "}
                        <Link to="/login">Login</Link>
                      </div>
                    )}
                  </Route>

                  <Route
                    exact
                    path="/"
                    component={() => (
                      <div>
                        logged out not found
                        <Link to="/login"> To login screen </Link>
                      </div>
                    )}
                  />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/terms" component={Terms} />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path="/" component={Requests} />
                  <Route exact path="/requests" component={Requests} />
                  <Route
                    exact
                    path="/requests/tickets"
                    component={TicketsRequest}
                  />
                  <Route
                    exact
                    path="/requests/fitness"
                    component={FitnessRequest}
                  />
                  <Route
                    exact
                    path="/requests/home-services"
                    component={HomeRequest}
                  />
                  <Route
                    exact
                    path="/requests/reservation"
                    component={ReservationRequest}
                  />

                  <Route
                    exact
                    path="/requests/other"
                    component={OtherRequest}
                  />
                  <Route
                    exact
                    path="/requests/submitted"
                    component={RequestSubmitted}
                  />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/promos" component={Promos} />
                  <Route exact path="/history" component={History} />
                  <Route exact path="/calendar" component={Calendar} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/login">
                    {this.props.isLoggedIn ? (
                      <Redirect to="/requests" />
                    ) : (
                      <Login />
                    )}
                  </Route>
                  <Route path="/" component={Requests}>
                    {/* <Redirect to='/requests'/> */}
                  </Route>
                </Switch>
              )}
            </div>
          </div>
        )}

        <div style={{ backgroundColor: "black" }}>
          <div className="footer">
            <div className="termsandPrivacyDesktop">
              <Link to={"/terms"} className="navLinks">
                Terms of Use
              </Link>
              <Link to={"/privacy"} className="navLinks">
                Privacy Policy
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <a
                href="https://www.instagram.com/livunltd/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{
                    border: "1px white solid",
                    borderRadius: "100px",
                    backgroundColor: "white",
                    transform: "scale(0.5)",
                  }}
                  src={instagram}
                  alt="IG"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/liv-unltd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    border: "1px white solid",
                    borderRadius: "100px",
                    backgroundColor: "white",
                    transform: "scale(0.5)",
                  }}
                  src={linkedin}
                  alt="LI"
                ></img>
              </a>
              <a
                href="https://www.facebook.com/LIVunLtd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    border: "1px white solid",
                    borderRadius: "100px",
                    backgroundColor: "white",
                    transform: "scale(0.5)",
                  }}
                  src={facebook}
                  alt="FB"
                ></img>
              </a>
            </div>
            <div>
              <p style={{ margin: 0 }}>212-784-2390</p>
              <p style={{ margin: 0 }}>info@livunltd.com</p>
            </div>
          </div>
          <div className="termsandPrivacyMobile">
            <Link
              to={"/terms"}
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              Terms of Use
            </Link>
            <p style={{ color: "white", marginBottom: 0 }}>l</p>
            <Link
              to={"/privacy"}
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: !!state.account.contactId,
  signedUp: !!state.account.displayName,
  email: state.auth.email,
});

export default withRouter(
  connect(mapStateToProps, { onASNameChange, onASClearFields })(Root)
);
