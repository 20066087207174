/** @format */

import React, { PureComponent } from "react";
import axios from "axios";
import "../css/historyFilter.css";
import HistoryFilter from "./HistoryFilter";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import HistoryDetailsModal from "./HistoryDetailsModal";
import down_arrow from "../assets/buttons/down-arrow.png";
import up_arrow from "../assets/buttons/up-arrow.png";
import search_icon from "../assets/buttons/search-icon.png";
import "../css/history.css";
import { GLOBAL_DOMAIN } from "../actions/types";
class History extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardId: [-1, -1],
      history: [],
      filterHistory: [],
      searchTerm: "",
      detailsId: "",
      showEventDetails: false,
      statusInProgress: true,
      statusConfirmed: true,
      statusCancelled: true,
      orderByNewToOld: true,
      orderByOldToNew: false,
      sortByDate: true,
      sortByAlphabetical: false,
      requestFitness: true,
      requestExperiences: true,
      requestHome: true,
      requestReservations: true,
      requestOther: true,
      timelineAll: true,
      timelineUpcoming: false,
      timelineWeek: false,
      timelineMonth: false,
      timelineYear: false,
    };
  }

  async UNSAFE_componentWillMount() {
    try {
      let checkHistoryResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/history`,
        {
          params: {
            contactId: localStorage.getItem("contactId"),
            token: localStorage.getItem("token"),
          },
        }
      );
      let history = checkHistoryResponse.data.records.sort((a, b) => {
        if (
          new Date(a.Preferred_Date__c).toISOString() >
          new Date(b.Preferred_Date__c).toISOString()
        ) {
          return -1;
        } else if (
          new Date(a.Preferred_Date__c).toISOString() <
          new Date(b.Preferred_Date__c).toISOString()
        ) {
          return 1;
        } else {
          return 0;
        }
      });

      this.setState({ history, filterHistory: history });
    } catch (error) {
      // If access token comes back expired
      try {
        let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
        localStorage.setItem("token", response.data);
        let checkHistoryResponse = await axios.get(
          `${GLOBAL_DOMAIN}/api/data/history`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
            },
          }
        );
        let history = checkHistoryResponse.data.records;
        this.setState({ history });
      } catch (error) {
        console.log("Failed reauthentication");
      }
    }
  }

  searchHistory = (search) => {
    let filteredHistory = this.state.history.filter((event) => {
      if (typeof event.Category__c === "string") {
        let cat = event.Category__c.toLowerCase();
        return cat.includes(search);
      }
      if (typeof event.Additional_Details__c === "string") {
        let cat = event.Additional_Details__c.toLowerCase();
        return cat.includes(search);
      }
      return false;
    });
    this.setState({ filterHistory: filteredHistory, searchTerm: search });
    return filteredHistory;
  };

  filterHistory = (filterValues) => {
    if (filterValues.sortByDate) {
      this.state.filterHistory.sort((a, b) => {
        let date1;
        let date2;
        if (a.Appointment_Date_Time__c) {
          date1 = this.parseISOString(a.Appointment_Date_Time__c);
        } else if (a.Preferred_Date__c) {
          date1 = new Date(a.Preferred_Date__c);
        } else {
          date1 = this.parseISOString(a.CreatedDate);
        }

        if (b.Appointment_Date_Time__c) {
          date2 = this.parseISOString(b.Appointment_Date_Time__c);
        } else if (b.Preferred_Date__c) {
          date2 = new Date(b.Preferred_Date__c);
        } else {
          date2 = this.parseISOString(b.CreatedDate);
        }

        if (filterValues.orderByNewToOld) {
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    } else {
      this.state.filterHistory.sort((a, b) => {
        let descA1 = a.Category__c ? a.Category__c.toUpperCase() : "OTHER";
        let descB1 = b.Category__c ? b.Category__c.toUpperCase() : "OTHER";

        let descA = descA1;
        let descB = descB1;

        if (descA < descB) {
          return -1;
        }
        if (descA > descB) {
          return 1;
        }

        let date1;
        let date2;

        if (a.Appointment_Date_Time__c) {
          date1 = this.parseISOString(a.Appointment_Date_Time__c);
        } else if (a.Preferred_Date__c) {
          date1 = new Date(a.Preferred_Date__c);
        } else {
          date1 = this.parseISOString(a.CreatedDate);
        }

        if (b.Appointment_Date_Time__c) {
          date2 = this.parseISOString(b.Appointment_Date_Time__c);
        } else if (b.Preferred_Date__c) {
          date2 = new Date(b.Preferred_Date__c);
        } else {
          date2 = this.parseISOString(b.CreatedDate);
        }

        if (filterValues.orderByNewToOld) {
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    }
    this.searchHistory(this.state.searchTerm);
    this.setState({ filterHistory: this.state.filterHistory });
  };
  parseISOString = (s) => {
    if (s) {
      let b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }
  };

  renderRequests = () => {
    const searchText = this.state.searchTerm;

    if (this.state.sortByDate) {
      this.state.history.sort((a, b) => {
        let date1;
        let date2;

        if (a.Appointment_Date_Time__c) {
          date1 = this.parseISOString(a.Appointment_Date_Time__c);
        } else if (a.Preferred_Date__c) {
          date1 = new Date(a.Preferred_Date__c);
        } else {
          date1 = this.parseISOString(a.CreatedDate);
        }

        if (b.Appointment_Date_Time__c) {
          date2 = this.parseISOString(b.Appointment_Date_Time__c);
        } else if (b.Preferred_Date__c) {
          date2 = new Date(b.Preferred_Date__c);
        } else {
          date2 = this.parseISOString(b.CreatedDate);
        }

        if (this.state.orderByNewToOld) {
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
    } else {
      this.state.history.sort((a, b) => {
        let descA1 = a.Category__c ? a.Category__c.toUpperCase() : "OTHER";
        let descB1 = b.Category__c ? b.Category__c.toUpperCase() : "OTHER";

        // let descA2 = a.Sub_Category__c ? a.Sub_Category__c : "";
        // let descB2 = b.Sub_Category__c ? b.Sub_Category__c : "";

        if (descA1 === "EXPERIENCES") {
          descA1 = "TICKETS";
        }

        if (descB1 === "EXPERIENCES") {
          descB1 = "TICKETS";
        }

        let descA = descA1;
        let descB = descB1;

        if (descA < descB) {
          return -1;
        }
        if (descA > descB) {
          return 1;
        }

        let date1;
        let date2;

        if (a.Appointment_Date_Time__c) {
          date1 = this.parseISOString(a.Appointment_Date_Time__c);
        } else if (a.Preferred_Date__c) {
          date1 = new Date(a.Preferred_Date__c);
        } else {
          date1 = this.parseISOString(a.CreatedDate);
        }

        if (b.Appointment_Date_Time__c) {
          date2 = this.parseISOString(b.Appointment_Date_Time__c);
        } else if (b.Preferred_Date__c) {
          date2 = new Date(b.Preferred_Date__c);
        } else {
          date2 = this.parseISOString(b.CreatedDate);
        }
        return this.state.orderNewToOld ? date2 - date1 : date1 - date2;
      });
    }

    let requestArray = [];

    this.state.history.map((item, i) => {
      // let date1;
      let dateTestFinal;

      if (item.Appointment_Date_Time__c) {
        // date1 = new Date(this.parseISOString(item.Appointment_Date_Time__c))
        //   .toString()
        //   .slice(0, 10)
        //   .toUpperCase();
        dateTestFinal = new Date(
          this.parseISOString(item.Appointment_Date_Time__c)
        );
      } else if (item.Preferred_Date__c) {
        // date1 = new Date(item.Preferred_Date__c)
        //   .toString()
        //   .slice(0, 10)
        //   .toUpperCase();
        dateTestFinal = new Date(item.Preferred_Date__c);
      } else {
        // date1 = new Date(this.parseISOString(item.CreatedDate))
        //   .toString()
        //   .slice(0, 10)
        //   .toUpperCase();
        dateTestFinal = new Date(this.parseISOString(item.CreatedDate));
      }

      let cat = item.Category__c ? item.Category__c.toUpperCase() : "OTHER";

      if (cat.toUpperCase() === "FITNESS/WELLNESS") {
        cat = "FITNESS";
      }

      if (cat.toUpperCase() === "EXPERIENCES") {
        cat = "TICKETS";
      }

      if (cat.toUpperCase() === "RESTAURANTS") {
        cat = "RESERVATIONS";
      }

      let subcat = item.Sub_Category__c ? item.Sub_Category__c : "";

      let subject = item.Subject ? item.Subject : "";

      let concierge = item.LIVunLtd_Concierge_or_REM__c
        ? item.LIVunLtd_Concierge_or_REM__c
        : "";

      let description = item.Additional_Details__c
        ? item.Additional_Details__c
        : subject;

      // let time = item.Preferred_Time__c ? item.Preferred_Time__c : "";

      let status = "IN PROGRESS";

      if (item.Status === "Cancelled") {
        status = "CANCELLED";
      }

      if (item.Fulfilled_and_Final__c) {
        status = "CONFIRMED";
      }

      let todayDate = new Date();

      // let conditionStatus = dateTestFinal < todayDate;

      if (item.Status !== "Cancelled" && dateTestFinal < todayDate) {
        status = "CONFIRMED";
      }

      // let pastRequest = false;

      // if (dateTestFinal < new Date() || status === "CANCELLED") {
      //   pastRequest = true;
      // }

      // CHECK THE FILTERS
      // FIRST IS THE STATUS FILTERS

      if (!this.state.statusInProgress && status === "IN PROGRESS") {
        return <span />;
      }
      if (!this.state.statusCancelled && status === "CANCELLED") {
        return <span />;
      }
      if (!this.state.statusConfirmed && status === "CONFIRMED") {
        return <span />;
      }

      // SECOND IS THE REQUEST TYPE FILTERS
      if (!this.state.requestFitness && cat === "FITNESS") {
        return <span />;
      }
      if (!this.state.requestExperiences && cat === "TICKETS") {
        return <span />;
      }
      if (!this.state.requestReservations && cat === "RESERVATIONS") {
        return <span />;
      }
      if (!this.state.requestHome && cat === "HOME") {
        return <span />;
      }
      if (!this.state.requestOther && cat === "OTHER") {
        return <span />;
      }
      if (!this.state.requestOther && cat === "LIFESTYLE") {
        return <span />;
      }

      // THIRD AND LAST IS BY DATE

      let today = new Date(); // 1 Feb -> 30 Jan

      if (this.state.timelineUpcoming && dateTestFinal < today) {
        return <span />;
      }
      if (
        this.state.timelineWeek &&
        dateTestFinal < new Date(new Date().setDate(new Date().getDate() - 7))
      ) {
        return <span />;
      }
      if (this.state.timelineWeek && dateTestFinal > new Date()) {
        return <span />;
      }
      if (
        this.state.timelineMonth &&
        dateTestFinal < new Date(new Date().setDate(new Date().getDate() - 31))
      ) {
        return <span />;
      }
      if (this.state.timelineMonth && dateTestFinal > new Date()) {
        return <span />;
      }
      if (
        this.state.timelineYear &&
        dateTestFinal < new Date(new Date().setDate(new Date().getDate() - 365))
      ) {
        return <span />;
      }
      if (this.state.timelineYear && dateTestFinal > new Date()) {
        return <span />;
      }

      // IF CASE UNFULFILLED, DO NOT INCLUDE

      if (item.Unfulfilled__c) {
        return <span />;
      }

      // IF CASE CATEGORY IS OUTREACH

      if (item.Category__c === "Outreach") {
        return <span />;
      }

      if (this.state.searchTerm === "") {
        requestArray.push(item);
      } else {
        if (
          cat.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          subcat.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          subject.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          concierge.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        ) {
          requestArray.push(item);
        }
      }
      return <span />;
    });

    return this.drawRequests(requestArray);
  };

  renderCard = (item, accordian) => {
    let date1;
    let dateFinal;
    let dateTestFinal;
    if (item.Appointment_Date_Time__c) {
      dateFinal = new Date(this.parseISOString(item.Appointment_Date_Time__c))
        .toUTCString()
        .slice(0, 16)
        .toUpperCase();
      dateTestFinal = new Date(
        this.parseISOString(item.Appointment_Date_Time__c)
      );
    } else if (item.Preferred_Date__c) {
      date1 = new Date(item.Preferred_Date__c);
      date1.setDate(date1.getDate() + 1);
      dateFinal = date1.toUTCString().slice(0, 16).toUpperCase();
      dateTestFinal = new Date(item.Preferred_Date__c);
    } else {
      dateFinal = new Date(this.parseISOString(item.CreatedDate))
        .toUTCString()
        .slice(0, 16)
        .toUpperCase();
      dateTestFinal = new Date(this.parseISOString(item.CreatedDate));
    }

    let cat = item.Category__c ? item.Category__c.toUpperCase() : "OTHER";

    if (cat.toUpperCase() === "FITNESS/WELLNESS") {
      cat = "FITNESS";
    }

    if (cat.toUpperCase() === "EXPERIENCES") {
      cat = "TICKETS";
    }

    if (cat.toUpperCase() === "RESTAURANTS") {
      cat = "RESERVATIONS";
    }

    let subcat = item.Sub_Category__c ? item.Sub_Category__c : "";

    // let subject = item.Subject ? item.Subject : "";

    // let concierge = item.LIVunLtd_Concierge_or_REM__c
    //   ? item.LIVunLtd_Concierge_or_REM__c
    //   : "";

    // let description = item.Description ? item.Description : subject;

    let time = item.Preferred_Time__c ? item.Preferred_Time__c : "";

    let status = "IN PROGRESS";

    if (item.Status === "Cancelled") {
      status = "CANCELLED";
    }
    if (item.Fulfilled_and_Final__c) {
      status = "CONFIRMED";
    }
    let todayDate = new Date();

    // let conditionStatus = dateTestFinal < todayDate;

    if (item.Status !== "Cancelled" && dateTestFinal < todayDate) {
      status = "CONFIRMED";
    }

    // let pastRequest = false;

    // if (dateTestFinal < new Date() || status === "CANCELLED") {
    //   pastRequest = true;
    // }

    // cat.toUpperCase()} {subcat ? `/ ${subcat.toUpperCase()}` : ''
    let header = "";
    let subheader = "";

    if (cat === "RESERVATIONS") {
      header = cat.toUpperCase();
    } else {
      header = cat.toUpperCase();
      subheader = subcat ? ` / ${subcat.toUpperCase()}` : "";
    }

    return (
      <Card className="history_card" key={`history-${item.Id}`}>
        <Accordion.Toggle
          as={Card.Header}
          variant="link"
          eventKey={item.Id}
          style={{
            backgroundColor: "#E9E9E9",
            opacity: status === "CONFIRMED" ? 0.5 : 1.0,
            border: "none",
            height: "78px",
            padding: "10px 5px 10px 10px",
          }}
          onClick={() => {
            if (this.state.cardId.includes(item.Id)) {
              if (accordian === 0) {
                this.setState({ cardId: [-1, this.state.cardId[1]] });
              } else {
                this.setState({ cardId: [this.state.cardId[0], -1] });
              }
            } else {
              this.setState({
                cardId:
                  accordian === 0
                    ? [item.Id, this.state.cardId[1]]
                    : [this.state.cardId[0], item.Id],
              });
            }
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col
              style={{
                display: "flex",
                flex: 2,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className={"proxima_bold"} style={{ margin: "0px" }}>
                {header + subheader}
              </p>
              <p className={"proxima_light"}>
                {dateFinal} <strong>|</strong> {time}
              </p>
            </Col>
            <p className={"proxima_light"}>
              {status}
              <img
                alt="details"
                style={{ paddingLeft: "10px", paddingRight: "20px" }}
                src={
                  this.state.cardId.includes(item.Id) ? up_arrow : down_arrow
                }
              />
            </p>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={item.Id}>
          <Card.Body
            className={"proxima_reg"}
            style={{
              backgroundColor: "#E9E9E9",
              opacity: status === "CONFIRMED" ? 0.5 : 1.0,
              border: "none",
            }}
          >
            {this.renderDetails(item)}
          </Card.Body>
        </Accordion.Collapse>
        <br />
      </Card>
    );
  };

  drawRequests = (requestArray) => {
    const oneCol = window.innerWidth < 993;

    if (requestArray.length === 0) {
      return [
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 100,
          }}
        >
          <div>
            <p
              className="loading"
              style={{
                marginTop: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: "36px",

                // color: "#efefef"
              }}
            >
              NOTHING FOUND
            </p>
          </div>
        </div>,
      ];
    }
    return oneCol ? (
      <Accordion>
        {requestArray.map((item, i) => this.renderCard(item, 0))}
      </Accordion>
    ) : (
      [
        <Accordion>
          {requestArray
            .filter((val, idx) => idx % 2 !== 1)
            .map((item, i) => this.renderCard(item, 0))}
        </Accordion>,
        <Accordion>
          {requestArray
            .filter((val, idx) => idx % 2 === 1)
            .map((item, i) => this.renderCard(item, 1))}
        </Accordion>,
      ]
    );
  };
  applyFilter = (filterValues) => {
    this.setState({ ...this.state, ...filterValues });
    this.filterHistory(filterValues);
  };
  handleClose = () => {
    this.setState({ showEventDetails: false });
  };

  renderDetails = (item) => {
    return <HistoryDetailsModal request={item} />;
  };

  render() {
    const oneCol = window.innerWidth < 993;

    return (
      <Container style={{ marginTop: "100px" }}>
        {this.state.history.length === 0 ? (
          <p
            className="loading"
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "#efefef",
            }}
          >
            RETREIVING HISTORY...
          </p>
        ) : (
          <Container className="justify-content-md-center">
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "35px",
              }}
            >
              <Col lg={8} md={8} sm={8}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form style={{ flex: 1 }}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="SEARCH"
                        value={this.state.searchTerm}
                        onChange={(e) => {
                          this.searchHistory(e.target.value);
                        }}
                        className="proxima_light"
                        style={{
                          border: "3px black solid",
                          height: "65px",
                          fontSize: "24px",
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                  <img
                    alt="sei"
                    src={search_icon}
                    style={{
                      position: "absolute",
                      height: "30px",
                      right: 30,
                      top: 17,
                    }}
                  ></img>
                </div>
              </Col>
              <HistoryFilter confirmFilter={this.applyFilter} />
            </Row>

            {oneCol ? (
              <Row>
                <Col>{this.renderRequests()}</Col>{" "}
              </Row>
            ) : (
              <Row>
                <Col lg={6} md={12}>
                  {this.renderRequests()[0]}
                </Col>
                <Col lg={6} md={12}>
                  {this.renderRequests()[1]}
                </Col>
              </Row>
            )}

            <Modal
              show={this.state.showEventDetails}
              onHide={this.handleClose}
              animation={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Event Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.showEventDetails && this.renderDetails()}
              </Modal.Body>
            </Modal>
          </Container>
        )}
      </Container>
    );
  }
}

export default History;
