import React, { PureComponent } from "react";
import mapboxgl from "mapbox-gl";
import Gold_Pin from "../assets/buttons/Gold_Pin.png";
import Red_Pin from "../assets/buttons/Red_Pin.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoibGl2dW5sdGQiLCJhIjoiY2szMG8xYWZzMDBlcDNmbnFhYTQ4M25pYiJ9.bwfxmzAfq1LPvjLqTamxZg";
class ReservationMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      venue_name: "",
      venue_neighborhood: "",
      venue_address: "",
      venue_buzz_words: "",
    };
  }
  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-73.9883, 40.7291],
      zoom: 11.6,
    });
    map.setMinZoom(8);
    const { restaurants } = this.props;
    map.on("load", function () {
      /* Image: An image is loaded and added to the map. */
      map.loadImage(Gold_Pin, function (error, image) {
        if (error) throw error;
        map.addImage("gold-pin", image);
        /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
        map.addLayer({
          id: "places",
          type: "symbol",
          /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: restaurants.map((item) => ({
                type: "Feature",
                properties: {
                  venue_name: item.Name,
                  venue_neighborhood: item.Neighborhood__c,
                  venue_address: item.Vendor_Address__c,
                  venue_buzz_words: item.Buzz_Words_For_App__c,
                },
                geometry: {
                  type: "Point",
                  coordinates: [item.Longitude__c, item.Latitude__c],
                },
              })),
              visibility: "none",
            },
          },
          layout: {
            "icon-image": "gold-pin",
            "icon-allow-overlap": true,
            "icon-size": 1,
            "icon-anchor": "bottom",
            visibility: "visible",
          },
        });
      });

      map.loadImage(Red_Pin, function (error, image) {
        if (error) throw error;
        map.addImage("red-pin", image);
        /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */

        map.addLayer({
          id: "selected",
          type: "symbol",
          /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: restaurants.map((item) => {
                return {
                  type: "Feature",
                  properties: {
                    venue_name: item.Name,
                    venue_neighborhood: item.Neighborhood__c,
                    venue_address: item.Vendor_Address__c,
                    venue_buzz_words: item.Buzz_Words_For_App__c,
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [item.Longitude__c, item.Latitude__c],
                  },
                };
              }),
            },
          },
          layout: {
            "icon-image": "red-pin",
            "icon-allow-overlap": true,
            "icon-size": 1,
            "icon-anchor": "bottom",
            visibility: "none",
          },
        });
      });
    });

    map.on("click", "places", (e) => {
      const {
        venue_name,
        venue_address,
        venue_neighborhood,
        venue_buzz_words,
      } = e.features[0].properties;

      map.getSource("selected").setData({
        type: "FeatureCollection",
        features: restaurants
          .filter((item) => {
            return item.Name === venue_name;
          })
          .map((item) => {
            return {
              type: "Feature",
              properties: {
                venue_name: item.Name,
                venue_neighborhood: item.Neighborhood__c,
                venue_address: item.Vendor_Address__c,
                venue_buzz_words: item.Buzz_Words_For_App__c,
              },
              geometry: {
                type: "Point",
                coordinates: [item.Longitude__c, item.Latitude__c],
              },
            };
          }),
      });

      map.setLayoutProperty("selected", "visibility", "visible");
      this.setState({
        venue_name,
        venue_neighborhood,
        venue_address,
        venue_buzz_words,
      });
    });

    // map.on("move", () => {
    //   this.setState({
    //     venue_name: ""
    //     // venue_neighborhood: "",
    //     // venue_address: "",
    //     // venue_buzz_words: ""
    //   });

    //   map.setLayoutProperty("selected", "visibility", "none");
    // });
  }

  render() {
    const infoHidden = !!this.state.venue_name ? "visible" : "hidden";
    return (
      <div style={{ height: "450px" }}>
        <div>
          {/* <div>
            Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom:
            {this.state.zoom}
          </div> */}
        </div>
        <div
          ref={(el) => (this.mapContainer = el)}
          style={{
            display: "inline-block",
            position: "absolute",
            border: "none",
            width: "100%",
            height: "400px",
            color: "#ffffff",
            zIndex: 1,
            padding: "6px",
          }}
        />
        <div
          style={{
            visibility: infoHidden,
            position: "absolute",
            backgroundColor: "black",
            color: "white",
            marginTop: "5px",
            marginLeft: "5px",
            padding: "10px",
            width: "400px",
            fontSize: "14px",
            zIndex: 2,
            opacity: 0.9,
          }}
        >
          <div className="map_info">
            <p>
              <strong>RESTAURANT:</strong> {this.state.venue_name.toUpperCase()}
            </p>
            <p>
              <strong>ADDRESS:</strong> {this.state.venue_address.toUpperCase()}
            </p>
            <p>
              <strong>NEIGHBORHOOD: </strong>
              {this.state.venue_neighborhood.toUpperCase()}
            </p>
            <p>
              <strong>DETAILS:</strong>{" "}
              {this.state.venue_buzz_words.toUpperCase()}
            </p>
            <button
              className="filter_button"
              onClick={() => {
                this.props.setRestaurant(this.state.venue_name);
                this.props.setNeighborhood(this.state.venue_neighborhood);
                this.setState({ venue_name: "" });
              }}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ReservationMap;
