import {
  AS_ATTEMPTED,
  AS_NAME_CHANGED,
  AS_EMAIL_CHANGED,
  AS_PASSWORD1_CHANGED,
  AS_PASSWORD2_CHANGED,
  AS_CLEARALL,
  AS_ERROR,
} from "./types";

export const attemptAccountSetup = () => {
  return {
    type: AS_ATTEMPTED,
  };
};

export const onASNameChange = (
  displayName,
  accountId,
  contactId,
  accountRegion
) => {
  return {
    type: AS_NAME_CHANGED,
    payload: { displayName, accountId, contactId, accountRegion },
  };
};

export const onASEmailChange = (text) => {
  return {
    type: AS_EMAIL_CHANGED,
    payload: text,
  };
};

export const onASPassword1Change = (text) => {
  return {
    type: AS_PASSWORD1_CHANGED,
    payload: text,
  };
};

export const onASPassword2Change = (text) => {
  return {
    type: AS_PASSWORD2_CHANGED,
    payload: text,
  };
};

export const onASClearFields = () => {
  return {
    type: AS_CLEARALL,
    payload: "clear all",
  };
};

export const onASError = (err) => ({
  type: AS_ERROR,
  payload: err,
});
