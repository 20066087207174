import React, { PureComponent } from "react";
import { Form, Button } from "react-bootstrap";
import firebase from "firebase/app";
import "../css/style.css";
class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldPass: "",
      newPass: "",
      confirmPass: "",
      error: false,
      errorMessage: "",
    };
  }

  onOldPassChange = (value) => {
    this.setState({ oldPass: value, error: false, errorMessage: "" });
  };
  onNewPassChange = (value) => {
    this.setState({ newPass: value, error: false, errorMessage: "" });
  };

  onConfirmPasschange = (value) => {
    this.setState({ confirmPass: value, error: false, errorMessage: "" });
  };

  confirmChangePassForm = () => {
    this.updatePassword(this.state);
  };

  updatePassword = async (formInfo) => {
    const { oldPass, newPass, confirmPass } = formInfo;
    if (oldPass === "" || newPass === "" || confirmPass === "") {
      this.setState({
        error: true,
        errorMessage: "All fields are required, please enter values.",
      });
      return;
    }
    if (newPass !== confirmPass) {
      this.setState({
        error: true,
        errorMessage: "Make sure new password fields match",
      });
      return;
    }
    const user = await firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      this.props.email,
      oldPass
    );
    try {
      await user.reauthenticateWithCredential(credential);
    } catch (err) {
      this.setState({
        error: true,
        errorMessage: "Current password incorrect",
      });
      return;
    }
    //change password
    try {
      await user.updatePassword(newPass);
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
      });
      return;
    }
    this.props.handleClose();
    this.props.successfulChange();
    this.setState({
      oldPass: "",
      newPass: "",
      confirmPass: "",
      error: false,
      errorMessage: "",
    });
  };

  render() {
    return (
      <Form style={{ marginTop: "15px" }}>
        <Form.Group controlId="formCurrentPassword">
          <Form.Control
            type="password"
            className="changePass"
            value={this.state.oldPass}
            placeholder="CURRENT PASSWORD"
            onChange={(e) => {
              this.onOldPassChange(e.target.value);
            }}
            style={{ textAlign: "center" }}
          />
        </Form.Group>
        <Form.Group controlId="formNewPassword1">
          <Form.Control
            type="password"
            className="changePass"
            value={this.state.newPass}
            placeholder="NEW PASSWORD"
            onChange={(e) => {
              this.onNewPassChange(e.target.value);
            }}
            style={{ textAlign: "center" }}
          />
        </Form.Group>
        <Form.Group controlId="formNewPassword2">
          <Form.Control
            type="password"
            className="changePass"
            value={this.state.confirmPass}
            placeholder="CONFIRM PASSWORD"
            onChange={(e) => {
              this.onConfirmPasschange(e.target.value);
            }}
            style={{ textAlign: "center" }}
          />
        </Form.Group>
        {this.state.error && (
          <p style={{ color: "red", fontSize: "12px" }}>
            ERROR: {this.state.errorMessage.toUpperCase()}
          </p>
        )}

        <Button
          variant="none"
          className="account_buttons"
          style={{
            color: "white",
            border: "2px white solid",
            marginRight: "10px",
          }}
          onClick={() => this.confirmChangePassForm()}
        >
          CHANGE PASSWORD
        </Button>
        <Button
          variant="none"
          className="account_buttons"
          style={{
            color: "white",
            border: "2px white solid",
          }}
          onClick={() => {
            this.setState({
              oldPass: "",
              newPass: "",
              confirmPass: "",
              error: false,
              errorMessage: "",
            });
            this.props.handleClose();
          }}
        >
          CANCEL
        </Button>
      </Form>
    );
  }
}

export default ChangePasswordForm;
