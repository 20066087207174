/** @format */

import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Toast,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import "../css/calendar.css";
import down_arrow from "../assets/buttons/down-arrow.png";
import up_arrow from "../assets/buttons/up-arrow.png";
import { GLOBAL_DOMAIN } from "../actions/types";

class Calendar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showToasts: false,
      showRSVPspinner: false,
      selectedActivation: "",
      toastInfo: {},
      events: [],
      rsvpData: [],
      waitlistData: [],
      cardId: "",
      section: 1,
    };
  }

  UNSAFE_componentWillMount = async () => {
    try {
      let checkEventsResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/events`,
        {
          params: {
            accountId: localStorage.getItem("accountId"),
            token: localStorage.getItem("token"),
          },
        }
      );
      let data = checkEventsResponse.data.records;

      if (localStorage.getItem("region") === "Florida") {
        // if (!true) {
        data = data.filter((item) => {
          if (
            item.Which_Building__c === "0014A00002J93VOQAZ" &&
            item.Region__c !== "Florida"
          ) {
            return false;
          }
          return true;
        });
      }
      this.setState({ events: data });

      let rsvpData = await axios.get(`${GLOBAL_DOMAIN}/api/data/events/rsvp`, {
        params: {
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });

      this.setState({ rsvpData: rsvpData.data.records });

      let waitlistData = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/events/waitlist`,
        {
          params: {
            contactId: localStorage.getItem("contactId"),
            token: localStorage.getItem("token"),
          },
        }
      );
      this.setState({ waitlistData: waitlistData.data.records });
    } catch (err) {
      console.log(err);
    }
  };

  getEvents = async () => {
    try {
      let checkEventsResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/events`,
        {
          params: {
            accountId: localStorage.getItem("accountId"),
            token: localStorage.getItem("token"),
          },
        }
      );
      this.setState({ events: checkEventsResponse.data.records });
      let rsvpData = await axios.get(`${GLOBAL_DOMAIN}/api/data/events/rsvp`, {
        params: {
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });

      this.setState({ rsvpData: rsvpData.data.records });

      let waitlistData = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/events/waitlist`,
        {
          params: {
            contactId: localStorage.getItem("contactId"),
            token: localStorage.getItem("token"),
          },
        }
      );
      this.setState({ waitlistData: waitlistData.data.records });
    } catch {}
  };

  rsvpButtonPressed = async (activationId, buttonLabel) => {
    this.setState({
      showRSVPspinner: true,
      selectedActivation: activationId,
    });
    // let result = null;
    if (buttonLabel === "RSVP") {
      let doubleCheck = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/events/doublecheck`,
        {
          params: {
            activationId: activationId,
            contactId: localStorage.getItem("contactId"),
            token: localStorage.getItem("token"),
          },
        }
      );

      if (
        doubleCheck.data.records[0].Total_Spots_Available__c &&
        doubleCheck.data.records[0].Total_Spots_Available__c > 0 &&
        doubleCheck.data.records[0].Total_Spots_Available__c <=
          doubleCheck.data.records[0].Total_RSVP_Count__c
      ) {
        await this.getEvents();
        this.setState({
          showToasts: true,
          toastInfo: {
            buttonLabel: "Uh Oh!",
            body: "It looks like the event already filled up. You can still add yourself to the Waitlist and will be grab a spot on a first-come, first-serve basis if someone drops out!",
          },
        });
        this.setState({
          showRSVPspinner: false,
          selectedActivation: "",
        });
        return;
      }
      await axios.get(`${GLOBAL_DOMAIN}/api/data/events/creatersvp`, {
        params: {
          activationId: activationId,
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });
      await this.getEvents();
      this.setState({
        showToasts: true,
        toastInfo: {
          buttonLabel: "RSVP Submitted!",
          body: "Thank you for RSVPing. You will be contacted shortly with details for the event.",
        },
      });
    }
    if (buttonLabel === "JOIN WAITLIST") {
      await axios.get(`${GLOBAL_DOMAIN}/api/data/events/createwaitlist`, {
        params: {
          activationId: activationId,
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });
      await this.getEvents();
      this.setState({
        showToasts: true,
        toastInfo: {
          buttonLabel: "Waitlist Submitted!",
          body: "Thank you for joining the Waitlist. You will be contacted by a concierge if a spot opens up for the event.",
        },
      });
    }

    if (buttonLabel === "CANCEL RSVP") {
      let rsvpRecord = this.state.rsvpData.find(
        (record) => record.Activation__c === activationId
      );
      await axios.get(`${GLOBAL_DOMAIN}/api/data/events/deletersvp`, {
        params: {
          rsvpId: rsvpRecord.Id,
          activationId: activationId,
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });
      await this.getEvents();
      this.setState({
        showToasts: true,
        toastInfo: {
          buttonLabel: "RSVP Deleted",
          body: "You have successfully cancelled your RSVP to the event. We hope to see you at another event again soon!",
        },
      });
    }
    if (buttonLabel === "CANCEL WAITLIST") {
      let waitlistRecord = this.state.waitlistData.find(
        (record) => record.Activation__c === activationId
      );

      await axios.get(`${GLOBAL_DOMAIN}/api/data/events/deletewaitlist`, {
        params: {
          waitlistId: waitlistRecord.Id,
          activationId: activationId,
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });

      await this.getEvents();
      this.setState({
        showToasts: true,
        toastInfo: {
          buttonLabel: "Waitlist Deleted",
          body: "You have successfully cancelled your spot on the Waitlist. We hope to see you at another event again soon!",
        },
      });
    }
    this.setState({
      showRSVPspinner: false,
      selectedActivation: "",
    });
  };

  renderRSVPbutton = (event) => {
    // let spotsAvailable = true;

    // let haveRecord = false;

    // let recordType = null;
    // let recordId = null;

    let buttonLabel = "RSVP";

    if (
      event.Total_Spots_Available__c &&
      event.Total_Spots_Available__c > 0 &&
      event.Total_Spots_Available__c <= event.Total_RSVP_Count__c
    ) {
      // spotsAvailable = false;
      buttonLabel = "JOIN WAITLIST";
    }

    if (this.state.rsvpData.length > 0) {
      if (
        this.state.rsvpData.some((record) => record.Activation__c === event.Id)
      ) {
        // haveRecord = true;
        // recordType = "RSVP";
        buttonLabel = "CANCEL RSVP";
      }
    }

    if (this.state.waitlistData.length > 0) {
      if (
        this.state.waitlistData.some(
          (record) => record.Activation__c === event.Id
        )
      ) {
        // haveRecord = true;
        // recordType = "Waitlist";
        buttonLabel = "CANCEL WAITLIST";
      }
    }
    return this.state.showRSVPspinner &&
      this.state.selectedActivation === event.Id ? (
      <div
        style={{
          display: "flex",
          marginRight: 50,
          borderWidth: 1,
          padding: "5px 25px",
          borderStyle: "solid",
        }}
      >
        <Spinner animation="border" />
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          marginRight: 50,
          borderWidth: 1,
          padding: "5px 25px",
          borderStyle: "solid",
        }}
        onClick={(e) => {
          e.stopPropagation();
          this.rsvpButtonPressed(event.Id, buttonLabel);
        }}
      >
        <p style={{ margin: 0 }}>{buttonLabel}</p>
      </div>
    );
  };

  renderToasts = () => {
    return (
      <>
        <Toast
          show={this.state.showToasts}
          autohide
          delay={10000}
          onClose={() => {
            this.setState({ showToasts: false });
          }}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 250,
          }}
        >
          <Toast.Header style={{ display: "flex" }}>
            {/* <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              /> */}
            <strong style={{ flex: 1 }}>
              {this.state.toastInfo.buttonLabel}
            </strong>
            {/* <small>just now</small> */}
          </Toast.Header>
          <Toast.Body>{this.state.toastInfo.body}</Toast.Body>
        </Toast>
      </>
    );
  };

  renderCards = () => {
    let filteredCalendar;

    if (this.state.section === 3) {
      filteredCalendar = this.state.events.filter((item) => {
        let eventResponse = false;
        if (this.state.rsvpData) {
          if (
            this.state.rsvpData.some(
              (record) => record.Activation__c === item.Id
            )
          ) {
            eventResponse = true;
            // haveRecord = true;
            // recordCount = recordCount + 1;
            // recordType = "RSVP";
            // buttonLabel = "CANCEL RSVP";
          }
        }
        if (this.state.waitlistData) {
          if (
            this.state.waitlistData.some(
              (record) => record.Activation__c === item.Id
            )
          ) {
            eventResponse = true; // haveRecord = true;
            // recordCount = recordCount + 1;
            // recordType = "Waitlist";
            // buttonLabel = "CANCEL WAITLIST";
          }
        }
        return eventResponse;
      });
    } else {
      filteredCalendar = this.state.events.filter((item) => {
        if (this.state.section === 0) {
          return true;
        } else {
          return this.state.section === 2
            ? item.Type_of_Event__c === "Fitness"
            : item.Type_of_Event__c !== "Fitness";
        }
      });
    }

    if (filteredCalendar.length === 0) {
      if (this.state.section === 3) {
        return (
          <p
            className="placeHolder"
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "black",
            }}
          >
            RSVP to Events and track them here!
          </p>
        );
      }
      return (
        <p
          className="placeHolder"
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            fontSize: "36px",
            color: "black",
          }}
        >
          Nothing Scheduled
        </p>
      );
    }
    return filteredCalendar.map((item, i) => {
      var a = item.Date_and_Time_of_Event__c.split(/[^0-9]/);
      var dateOfEvent = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
      dateOfEvent = dateOfEvent.toUTCString().slice(0, 16).toUpperCase();
      return (
        <Card style={{ border: "none" }} key={item.Id}>
          <Col
            xs={12}
            key={`history-${item.Id}`}
            style={{
              display: "borderBox",
              border: "white 3px solid",
              padding: "5px",
              borderRadius: "10px",
              minWidth: "300px",
            }}
          >
            <Accordion.Toggle
              as={Card.Header}
              eventKey={item.Id}
              style={{
                backgroundColor:
                  item.Type_of_Event__c !== "Fitness" ? "#CCE0EE" : "#E9E9E9",
                border: "none",
              }}
              onClick={() => {
                if (this.state.cardId === item.Id) {
                  this.setState({ cardId: "" });
                } else {
                  this.setState({ cardId: item.Id });
                }
              }}
            >
              <div
                style={{
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                  display: "flex",
                  // flex: 1,
                }}
              >
                <div style={{ flex: 1 }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "100%",
                    flex: 1,
                    marginLeft: 40,
                  }}
                >
                  <p
                    className="title"
                    style={{ margin: "0px", textAlign: "center" }}
                  >
                    {item.Name ? item.Name.toUpperCase() : ""}
                  </p>
                  <p
                    className="sub_title"
                    style={{
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    {`${dateOfEvent}`} <strong>|</strong>{" "}
                    {`${item.Start_Time__c}`}
                    {`${
                      item.End_Time__c !== null ? " - " + item.End_Time__c : ""
                    }`}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flex: 1,
                  }}
                >
                  {this.renderRSVPbutton(item)}

                  <img
                    alt="details"
                    src={this.state.cardId === item.Id ? up_arrow : down_arrow}
                    // style={{ marginBottom: "25px" }}
                  />
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.Id}>
              <Card.Body
                style={{
                  backgroundColor:
                    // this.state.section === 0 &&
                    item.Type_of_Event__c !== "Fitness" ? "#CCE0EE" : "#E9E9E9",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="details">
                  {item.Event_Details__c
                    ? item.Event_Details__c.toUpperCase()
                    : ""}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Col>
        </Card>
      );
    });
  };

  render() {
    return (
      <div style={{ marginTop: "10vh", marginBottom: "165px" }}>
        {this.state.events.length === 0 ? (
          <p
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "#efefef",
            }}
          >
            RETREIVING EVENTS...
          </p>
        ) : (
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Row style={{ marginBottom: "3vh" }}>
              {/* <Button
                variant="none"
                className="calendar_button"
                onClick={() => this.setState({ section: 0 })}
                style={
                  this.state.section === 0
                    ? { fontSize: "36px" }
                    : { color: "LightGray", fontSize: "36px" }
                }
              >
                ALL
              </Button> */}
              <Button
                variant="none"
                className="calendar_button"
                onClick={() => this.setState({ section: 1 })}
                style={
                  this.state.section === 1
                    ? { fontSize: "36px" }
                    : { color: "LightGray", fontSize: "36px" }
                }
              >
                SOCIAL
              </Button>
              <Button
                variant="none"
                className="calendar_button"
                onClick={() => this.setState({ section: 2 })}
                style={
                  this.state.section === 2
                    ? { fontSize: "36px" }
                    : { color: "LightGray", fontSize: "36px" }
                }
              >
                FITNESS
              </Button>
              <Button
                variant="none"
                className="calendar_button"
                onClick={() => this.setState({ section: 3 })}
                style={
                  this.state.section === 3
                    ? { fontSize: "36px" }
                    : { color: "LightGray", fontSize: "36px" }
                }
              >
                MINE
              </Button>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "3px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "3px",
                width: "100%",
              }}
            >
              <Accordion style={{ width: "100%" }}>
                {this.renderCards()}
              </Accordion>
            </Row>
          </Container>
        )}
        <div
          // aria-live="polite"
          // aria-atomic="true"
          style={{
            position: "absolute",
            top: 20,
            right: 20,

            minHeight: "100px",
          }}
        >
          {this.renderToasts()}
        </div>
      </div>
    );
  }
}

export default Calendar;
