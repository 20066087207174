import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
class Privacy extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div style={{ fontFamily: "proximanova_light" }}>
        {/* <p className="privacyBackButton">&lt; RETURN */}
        <div
          style={{
            position: "relative",
            width: "100%",
            textAlign: "center",
            fontFamily: "proximanova_light",
            marginTop: 100,
          }}
        >
          {/* Left side: Return link */}
          <Link
            to={"/"}
            className="privacyBackButton"
            style={{
              position: "absolute",
              left: "0px", // Adjust this as needed for spacing from the left edge
              fontSize: "16px",
              fontFamily: "proximanova_light",
            }}
          >
            &lt; Return
          </Link>

          <p
            style={{
              fontSize: "16px",
              margin: 0,
              fontWeight: "bold",
              fontFamily: "proximanova_light",
            }}
          >
            Privacy Policy
          </p>
        </div>
        <div className="PrivacyContainer">
          <p style={{ fontWeight: "bold" }}>
            The Amenity Collective Privacy Policy
          </p>
          <p> Last Updated and Effective as of: 7/1/2024</p>
          <p>
            This Privacy Policy (“Privacy Policy” or “Policy”), except as
            otherwise set forth below, applies to the collection and use of
            Personal Information by The Amenity Collective together with its
            Corporate Affiliates (as defined below) listed at{" "}
            <a
              href="mailto:amenitycollective.com/companies"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              amenitycollective.com/companies
            </a>{" "}
            (referred to herein collectively as “TAC,” “Company”, or “we,” or
            “us,” or “our”). The Policy describes the Company’s practices
            regarding the collection, use, disclosure, and sale of Personal
            Information based on your interactions with us, including on our
            websites, applications, mobile apps, forums, blogs listed at{" "}
            <a
              href="mailto:amenitycollective.com/companies"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              amenitycollective.com/companies
            </a>{" "}
            (together with any subdomains, each a “Site” and collectively, the
            “Sites”), and other online or offline offerings and your other
            interactions with us (collectively, the “Services”).
          </p>
          <p>
            You should review this Privacy Policy and our Terms of Use carefully
            because, by using this Site and otherwise interacting with us, you
            agree to the terms of this Privacy Policy.
          </p>
          <p>
            We collect, process and store various types of Personal
            Information. For purposes of this Policy, “Personal Information”
            means information that identifies, relates to, describes, is
            reasonably capable of being associated with, or could reasonably be
            linked, directly or indirectly, with a particular individual. It
            does not include de-identified or aggregate information that cannot
            be linked, directly or indirectly, with a particular individual.
          </p>
          <p>
            If an organization with which you are associated (an “Organization”)
            signs up to use our Services, we may receive Personal Information
            about you in connection with our provision of the Services to your
            Organization. To the extent we process that Personal Information
            solely in order to provide the Services to your Organization, we
            will act as a processor on behalf of your Organization in respect of
            that Personal Information, which means: we will handle that Personal
            Information solely at the direction of your Organization; your
            Organization’s privacy policy (and not this Privacy Policy) will
            apply to the processing of that Personal Information; and your
            Organization (and not us) is responsible for obtaining all necessary
            consents and providing you with all requisite information as
            required by applicable law. To the extent we process your Personal
            Information for any other lawful business purpose of ours, this
            Privacy Policy will apply to the processing of such Personal
            Information.
          </p>
          <p style={{ fontWeight: "bold" }}>
            1. Personal Information We Collect – Sources and Categories
          </p>
          <p>
            We may collect your Personal Information when you visit our Sites
            (including when you complete a contact form on one of our Sites),
            request information about or use our Services, book an appointment,
            register for mailing lists, interact with the Sites, email or call
            us, submit a review or evaluation of our Services, or when you
            voluntarily provide information to us through an email or phone call
            (including customer service calls). We also may receive information
            about you from third-party sources as well as from publicly
            available sources and online directory searches. 4882-6403-3116, v.
            11
          </p>
          <p>
            The categories of Personal Information we collect from these sources
            include the following:
          </p>

          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <strong>Personal identifiers:</strong> name; business with which you
            are associated, if any, and your role within that business; email
            address; address; telephone numbers; property attributes, values,
            and sales; customer number; account username and password; and IP
            address, or other unique identifier.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <strong>Commercial and financial information:</strong> records of
            products or Services ordered or considered and products and Services
            of interest to you; whether you are a new or existing customer;
            other purchasing or consuming histories; and payment information
            (including credit card information).
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <strong>Internet or other electronic activity information:</strong>{" "}
            your device and browser type, your browsing and search history on
            our Sites, and information regarding your interaction with our Sites
            and our advertisements.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <strong>Geolocation data:</strong> latitude and longitude of
            location where we are providing Services.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <strong>Audio information:</strong> recordings of customer service
            calls for quality assurance purposes.
          </p>
          <p style={{ fontWeight: "bold" }}>
            2. Purposes for Collection of Personal Information
          </p>
          <p>
            We use your Personal Information to provide you with the information
            you request and Services that are ordered by you or on your behalf.
            We use the information you provide us to contact you from time to
            time, to provide you with information about our business, customer
            support, and (unless you opt out) advertisements and marketing
            promotions and offers about products or services we think may be of
            interest to you, including our services and those of our Corporate
            Affiliates and of our strategic partners. If you opt in, we may
            contact you by SMS text message, including regarding billing
            matters.
          </p>
          <p>
            We also use Personal Information about your use of our Sites to
            monitor or improve our Sites; for internal business analysis; to
            prevent fraud, activities that violate our Terms of Use or another
            agreement with us or that are illegal; and to protect our rights and
            the rights and safety of our users or others.
          </p>
          <p style={{ fontWeight: "bold" }}>
            3. Disclosure of Personal Information
          </p>
          <p>
            We generally disclose information we gather from you under this
            Privacy Policy to the following types of third parties and as
            otherwise set forth in this Privacy Policy or as specifically
            authorized by you:
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <u>Laws and Legal Rights:</u> We may also share your Personal
            Information as required or permitted by law to comply with a
            subpoena or similar legal process or government request, or when we
            believe in good faith that disclosure is legally 4882-6403-3116, v.
            11 required or otherwise necessary to protect our rights and
            property or the rights, property or safety of others, including to
            law enforcement agencies, national security authorities, and
            judicial and regulatory authorities. We may also share your Personal
            Information with third parties to help detect and protect against
            fraud or data security vulnerabilities.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> <u>Sale of Business.</u>{" "}
            We may share or transfer your Personal Information to a third party
            in the event of an actual or potential sale, merger, bankruptcy,
            reorganization of TAC or our Corporate Affiliates or other
            restructuring or in connection with a strategic investment by a
            third party in TAC or our Corporate Affiliates.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <u>Outside Contractors.</u> We may employ independent contractors,
            vendors, and suppliers (collectively, &quot;Outside
            Contractors&quot;) to provide specific services and products related
            to the Sites and/or Services, such as hosting and maintaining the
            Service, providing credit card processing and fraud screening, and
            developing applications for the Sites and/or Services. In the course
            of providing products or services to us, these Outside Contractors
            may have access to information collected under this Privacy Policy,
            including your Personal Information. We use reasonable efforts to
            ensure that these Outside Contractors are capable of protecting the
            security of your Personal Information.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <u>Professional Advisors.</u> We may provide your information
            (including Personal Information) to professional advisors, such as
            lawyers, auditors, bankers, and insurers, where necessary in the
            course of the professional services that they render to us.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <u>Corporate Affiliates:</u> We may also disclose information
            (including Personal Information) about you to our Corporate
            Affiliates. For purposes of this Privacy Policy: &quot;Corporate
            Affiliate&quot; means any person or entity which directly or
            indirectly controls, is controlled by or is under common control
            with TAC, whether by ownership or otherwise; and “control” means
            possessing, directly or indirectly, the power to direct or cause the
            direction of the management, policies or operations of an entity,
            whether through ownership of fifty percent (50%) or more of the
            voting securities, by contract or otherwise.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span>{" "}
            <u>Third Parties Generally:</u> We may provide to third parties
            information, including where such information is combined with
            similar information of other users of our Sites and/or Services. For
            example, we might inform third parties regarding the number of
            unique users who use the Sites and/or Services, the demographic
            breakdown of our users of the Sites and/or Services, or the products
            and/or Services purchased and the vendors of such products and
            services. In addition to the above, when users use our Sites, third
            parties (including without limitation third-party analytics service
            providers and commercial partners) may directly collect information
            about our users’ online activities over time and across different
            websites. In particular, we share information with third-party
            digital 4882-6403-3116, v. 11 advertising networks by allowing such
            third parties to place cookies or other trackers on our Sites that
            collect information about your online activities over time and
            across different websites or applications. These parties may then
            use the information they collect to provide you with personalized
            content and present you with third-party products or services in
            which you may be interested. For more information about the use of
            cookies and trackers, see the section “Our Use of Cookies and
            Analytics” below. Please note further that the Sites use Google
            Analytics, including its data reporting features. Information
            collected by Google Analytics includes but is not limited to web
            metrics. For information on how Google Analytics collects and
            processes data, please see the site “How Google uses data when you
            use our partners&#39; sites or apps”, currently located at{" "}
            <a
              href="www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              www.google.com/policies/privacy/partners/
            </a>{" "}
            . For information on opting out of Google Analytics, we encourage
            you to visit Google’s website, including its list of currently
            available opt-out options presently located at{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              https://tools.google.com/dlpage/gaoptout
            </a>{" "}
            .
          </p>
          <p style={{ fontWeight: "bold" }}>
            4. Our Use of Cookies and Analytics
          </p>
          <p>
            We may send text files (e.g., “cookies” or other cached files) or
            images to your web browser. Such text files and images are used for
            technical convenience to store information on your computer. For
            instance, we may use a session cookie to store form information that
            you have entered so that you do not have to enter such information
            again. We may use information stored in such text files and images
            to customize your experience on the Sites and to monitor use of the
            Sites. We may also allow third parties to use cookies and collect
            information about your activities over time and across the Internet,
            including to suggest products or services which they determine you
            may be interested in receiving.
          </p>
          <p>
            You may set your browser to notify you when you receive a cookie.
            Many web browsers also allow you to block cookies. If you block
            cookies, you may not be able to access certain parts of the Sites.
            You can disable cookies from your computer system by following the
            instructions on your browser or at{" "}
            <a
              href="www.youradchoices.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              www.youradchoices.com
            </a>{" "}
            .
          </p>
          <p>
            Note that information collected from cookies and other similar
            technologies (which may include device identifiers) may be used to
            send you online advertising for the Services. In particular,
            information collected from cookies (including cookies placed by
            third-party vendors, such as Google and its partners) and other
            similar technologies (which may include device identifiers) may be
            used to deliver advertisements to Site visitors when such visitors
            are visiting other websites, including Facebook and LinkedIn. These
            third- party vendors may use cookies and/or device identifiers to
            serve ads based on your past visits to the Site. You may opt out of
            a third-party vendor’s use of cookies and/or device identifiers for
            personalized advertising by visiting{" "}
            <a
              href="https://thenai.org/opt-out/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              https://thenai.org/opt-out/
            </a>{" "}
            .
          </p>
          <p>
            We use Google Analytics to evaluate the use of our website. Google
            Analytics uses cookies and other identifiers to collect information,
            such as how often users visit the website, what pages they visit
            when they do so, and what other websites they visited prior to
            visiting our website. Our Sites also utilizes Google Tag Manager.
            Google may 4882-6403-3116, v. 11 collect your IP address, data
            related to the device/browser you are using, and other information
            about your use of our website. Google Analytics’ data practices are
            governed by the Google’s applicable Terms of Service and Privacy
            Policy, as they may be amended from time to time. You can view the
            Google Privacy Policy here:
          </p>
          <a
            href="https://policies.google.com/privacy?hl=en-US#infocollect"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://policies.google.com/privacy?hl=en-US#infocollect
          </a>
          <p>
            In addition to Google Analytics and Google Tag Manager, the Sites
            use third-party service platforms (including to help analyze how
            users use the Sites). These third-party service platforms may place
            cookies on your computer or mobile device. If you would like to
            disable &quot;third party&quot; cookies, you may be able to turn
            them off by going to the third party&#39;s website. Here are links
            to the other third-party platforms we use apart from Google:
          </p>
          <a
            href="https://wordpress.org/about/privacy/cookies/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://wordpress.org/about/privacy/cookies/
          </a>
          <br />
          <br />
          <a
            href="https://www.joomla.org/cookie-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://www.joomla.org/cookie-policy.html
          </a>
          <br />
          <br />
          <a
            href="https://www.squarespace.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://www.squarespace.com/privacy
          </a>
          <br />
          <br />
          <a
            href="https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code
          </a>
          <br />
          <br />
          <a
            href="https://birdeye.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://birdeye.com/privacy/
          </a>
          <br />
          <br />
          <a
            href="https://terms.yelp.com/privacy/en_us/20220831_en_us/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://terms.yelp.com/privacy/en_us/20220831_en_us/
          </a>
          <br />
          <br />
          <a
            href="https://www.salesforce.com/company/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://www.salesforce.com/company/privacy/
          </a>
          <br />
          <br />
          <a
            href="https://help.nextdoor.com/s/article/Privacy-Policy?language=en_US"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://help.nextdoor.com/s/article/Privacy-Policy?language=en_US
          </a>
          <br />
          <br />
          <a
            href="https://www.facebook.com/privacy/policies/cookies/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://www.facebook.com/privacy/policies/cookies/
          </a>
          <p>
            Information collected from some cookies placed on the Sites is used
            to deliver advertisements to TAC Site visitors when such visitors
            are visiting other websites, including Facebook and LinkedIn. We may
            also combine information collected from cookies with information
            that you may provide, such as information provided in a form that
            you complete.
          </p>
          <p style={{ fontWeight: "bold" }}>5. Log Files</p>
          <p>
            We also collect information through our Internet log files, which
            record data such as IP addresses, browser types, domain names, and
            other anonymous statistical data involving the use of the Sites.
            This information may be used to analyze trends, to administer the
            Sites, to monitor the use of the Sites, and to gather general
            demographic information. We may link this information to Personal
            Information for these and other purposes such as personalizing your
            experience on the Sites and evaluating the Sites in general.
            4882-6403-3116, v. 11
          </p>
          <p style={{ fontWeight: "bold" }}>6. Do Not Track</p>
          <p>
            “Do Not Track” is a privacy preference that users can set in certain
            web browsers. We do not comply with automated browser signals
            regarding tracking mechanisms, which may include “do not track”
            instructions.
          </p>
          <p style={{ fontWeight: "bold" }}>
            7. Personal Information of Minors
          </p>
          <p>
            Our products and Services are not directed to minors under the age
            of 13 and we do not knowingly collect or sell the Personal
            Information of minors under 16.
          </p>
          <p style={{ fontWeight: "bold" }}>
            8. How We Keep Your Personal Information Secure
          </p>
          <p>
            We implement and maintain reasonable security appropriate to the
            nature of the Personal Information that we collect, use, retain,
            transfer, or otherwise process. Our reasonable security program is
            implemented and maintained in accordance with applicable law and
            relevant standards. However, there is no perfect security, and
            reasonable security is a process that involves risk management
            rather than risk elimination. While we are committed to developing,
            implementing, maintaining, monitoring, and updating a reasonable
            information security program, no such program can be perfect; in
            other words, all risk cannot reasonably be eliminated. Data security
            incidents and breaches can occur due to vulnerabilities, criminal
            exploits or other factors that cannot reasonably be
            prevented. Accordingly, while our reasonable security program is
            designed to manage data security risks and thus help prevent data
            security incidents and breaches, it cannot be assumed that the
            occurrence of any given incident or breach results from our failure
            to implement and maintain reasonable security.
          </p>
          <p style={{ fontWeight: "bold" }}>9. Shine the Light</p>
          <p>
            California Civil Code Section 1798.83, also known as the “Shine the
            Light” law, permits California residents to annually request, free
            of charge, information about the Personal Information (if any)
            disclosed to third parties for direct marketing purposes in the
            preceding calendar year. We may use Personal Information to market
            third party products and services we believe may be of interest to
            our customers and we may share Personal Information with third
            parties (such as our Corporate Affiliates) for their own marketing
            purposes.
          </p>
          <p style={{ fontWeight: "bold" }}>
            10. Our Commitment to Honoring Your Rights
          </p>
          <p>
            If you exercise any of your rights described in this Privacy Policy,
            we will continue to treat you fairly.
          </p>
          <p style={{ fontWeight: "bold" }}>11. Changes to This Policy</p>
          <p>
            We will review and update this Policy as required to keep current
            with rules and regulations, new technologies and security standards.
            We will post those changes on the website or update the Privacy
            Policy modification date below. In certain cases, and if the changes
            are material, you will be notified via email or a notice on our
            website. 4882-6403-3116, v. 11
          </p>
          <p style={{ fontWeight: "bold" }}>12. Other Websites</p>
          <p>
            Our Sites may provide you with access to other websites and
            services. This may include providing you with the ability to
            automatically post updates on Facebook and Twitter. Please be aware
            that we are not responsible for the privacy practices of any
            websites or services other than the Sites. A link to a third-party
            website does not constitute or imply endorsement by us.
            Additionally, we cannot guarantee the quality or accuracy of
            information presented on those websites. We encourage you to read
            the privacy policies or statements of each and every such website
            and service. This Privacy Policy applies solely to information
            collected by us through the Sites and our Services.
          </p>
          <p style={{ fontWeight: "bold" }}>13. Public Areas</p>
          <p>
            The Sites may feature various community areas, blogs, and other
            public forums (the “Public Areas”) where Sites users with similar
            interests can share information and post questions for others to
            answer. These Public Areas are open to the public and should not be
            considered private. We cannot prevent information included within a
            Public Area from being used in a manner inconsistent with this
            Privacy Policy, the law, or your personal privacy. We are not
            responsible for the results of such postings or for the accuracy of
            any information contained in those postings.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Any information you share in a Public Area (including Personal
            Information) is by design open to the public and is not private. You
            should think carefully before posting any information in any Public
            Area. What you post can be seen, disclosed to or collected by others
            and may be used by others in ways we cannot regulate or predict. As
            with any public forum on any website, the information you post may
            also show up in third-party search engines like Google, Yahoo, and
            Bing. If you mistakenly post Personal Information in a Public Area,
            you can send us an e- mail to request that we remove it by
            contacting us at{" "}
            <a
              href="mailto:privacy@amenitycollective.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              privacy@amenitycollective.com
            </a>{" "}
            . You should understand that in some cases, we may not be able to
            remove your information.
          </p>
          <p style={{ fontWeight: "bold" }}>14. Accessibility</p>
          <p>
            We are committed to ensuring that our communications are accessible
            to people with disabilities. To make accessibility-related requests
            or report barriers, please contact us at{" "}
            <a
              href="mailto:accessibility@amenitycollective.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              accessibility@amenitycollective.com
            </a>{" "}
            .
          </p>
          <p style={{ fontWeight: "bold" }}>15. Contact Us</p>
          <p>
            If there are any questions regarding this Privacy Policy or to
            request a copy of this Privacy Policy in another format you may
            contact us at{" "}
            <a
              href="mailto:privacy@amenitycollective.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              privacy@amenitycollective.com
            </a>{" "}
            .
          </p>
          <p style={{ fontWeight: "bold" }}>
            16. Additional Information from Amenity Pool Services for Texas
            Consumers
          </p>
          <p>
            This Section 16 applies to Poolman 2000, LLC d/b/a Amenity Pool
            Services (“Amenity Pool Services”) only to the extent that Amenity
            Pool Services is regulated by the Texas 4882-6403-3116, v. 11 Data
            Privacy and Security Act (TDPSA) and if you are a Texas resident and
            a “consumer” as defined in the TDPSA.
          </p>
          <p>
            As used in this Section 16: “personal data” has the meaning set
            forth in the TDPSA; and “we,” or “us,” or “our” refers to Amenity
            Pool Services.
          </p>
          <p>
            Without limitation of any other provision in this Privacy Policy, in
            connection with the Services: Amenity Pool Services processes the
            categories of personal data described in Sections 1, 4, and 5 of
            this Privacy Policy for the purposes described in Sections 2, 4, and
            5 of this Privacy Policy; and Amenity Pool Services may disclose
            each of the categories of personal data described in Sections 1, 4,
            and 5 of this Privacy Policy to the categories of third parties
            described in Section 3 of this Privacy Policy. Under the TDPSA, you
            may have the right under certain circumstances to:
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> Confirm whether we
            process your personal data and to access such personal data.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> Correct inaccuracies in
            your personal data, taking into account the personal data’s nature
            and our purpose for processing it.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> Delete certain personal
            data we may hold about you.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> Data portability.
          </p>
          <p>
            <span style={{ fontSize: "12px" }}>●</span> To the extent
            applicable, opt-out of personal data processing for targeted
            advertising, sales, or profiling in furtherance of decisions that
            produce legal or similarly significant effects.
          </p>
          <p>
            To exercise any of these rights (to the extent the TDPSA grants such
            rights to you with respect to our processing of your personal data)
            please contact us using the contact details set out in this Section
            16.
          </p>
          <p>
            To appeal a decision regarding a request to exercise your rights,
            please contact us using the contact details set out in this Section
            16. If possible, it would be helpful if you can detail the basis of
            your appeal in your communication.
          </p>
          <p>
            Please note that to the extent we consider Deidentified Data outside
            the scope of the TDPSA because it is not identifiable, then, to the
            extent required by the TDPSA, we hereby publicly commit to process
            Deidentified Data in our possession only in a de- identified fashion
            and not attempt to re-identify such Deidentified Data. “Deidentified
            Data” means data that cannot reasonably be linked to an identified
            or identifiable Texas resident, or a device linked to that Texas
            resident.
          </p>
          <p>
            If you have any questions regarding this Section 16 or if you would
            like to exercise any of your rights under the TDPSA, please email
            Amenity Pool Services at{" "}
            <a
              href="mailto:privacy@amenitycollective.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              privacy@amenitycollective.com
            </a>{" "}
            .
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Privacy);
