/** @format */

import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Button,
  Popover,
} from "react-bootstrap";
import filter_icon from "../assets/buttons/filter.png";
import "../css/historyFilter.css";
import box from "../assets/img/box.png";
import box_x from "../assets/img/box_x.png";
class HistoryFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statusInProgress: true,
      statusConfirmed: true,
      statusCancelled: true,
      orderByNewToOld: true,
      orderByOldToNew: false,
      sortByDate: true,
      sortByAlphabetical: false,
      requestFitness: true,
      requestExperiences: true,
      requestHome: true,
      requestReservations: true,
      requestOther: true,
      timelineAll: true,
      timelineUpcoming: false,
      timelineWeek: false,
      timelineMonth: false,
      timelineYear: false,
    };
  }

  resetFilter = () => {
    this.props.confirmFilter({
      statusInProgress: true,
      statusConfirmed: true,
      statusCancelled: true,
      orderByNewToOld: true,
      orderByOldToNew: false,
      sortByDate: true,
      sortByAlphabetical: false,
      requestFitness: true,
      requestExperiences: true,
      requestHome: true,
      requestReservations: true,
      requestOther: true,
      timelineAll: true,
      timelineUpcoming: false,
      timelineWeek: false,
      timelineMonth: false,
      timelineYear: false,
    });

    this.setState({
      statusInProgress: true,
      statusConfirmed: true,
      statusCancelled: true,
      orderByNewToOld: true,
      orderByOldToNew: false,
      sortByDate: true,
      sortByAlphabetical: false,
      requestFitness: true,
      requestExperiences: true,
      requestHome: true,
      requestReservations: true,
      requestOther: true,
      timelineAll: true,
      timelineUpcoming: false,
      timelineWeek: false,
      timelineMonth: false,
      timelineYear: false,
    });
  };

  statusTapped = (index) => {
    if (index === 0) {
      this.setState({ statusInProgress: !this.state.statusInProgress });
    }

    if (index === 1) {
      this.setState({ statusConfirmed: !this.state.statusConfirmed });
    }

    if (index === 2) {
      this.setState({ statusCancelled: !this.state.statusCancelled });
    }
  };

  orderTapped = (index) => {
    if (index === 0) {
      this.setState({
        sortByDate: true,
        orderByNewToOld: true,
        orderByOldToNew: false,
        sortByAlphabetical: false,
      });
    } else {
      this.setState({
        sortByDate: true,
        orderByNewToOld: false,
        orderByOldToNew: true,
        sortByAlphabetical: false,
      });
    }
  };

  sortTapped = (index) => {
    if (index === 0) {
      this.setState({
        sortByDate: true,
        sortByAlphabetical: false,
        orderByNewToOld: false,
        orderByOldToNew: false,
      });
    } else if (index === 2) {
      this.setState({
        sortByDate: false,
        sortByAlphabetical: true,
        orderByNewToOld: false,
        orderByOldToNew: false,
      });
    }
  };

  requestTapped = (index) => {
    if (index === 0) {
      this.setState({ requestFitness: !this.state.requestFitness });
    }
    if (index === 1) {
      this.setState({ requestExperiences: !this.state.requestExperiences });
    }
    if (index === 2) {
      this.setState({ requestHome: !this.state.requestHome });
    }
    if (index === 3) {
      this.setState({ requestReservations: !this.state.requestReservations });
    }
    if (index === 4) {
      this.setState({ requestOther: !this.state.requestOther });
    }
  };

  timelineTapped = (index) => {
    if (index === 0) {
      this.setState({
        timelineAll: true,
        timelineUpcoming: false,
        timelineWeek: false,
        timelineMonth: false,
        timelineYear: false,
      });
    }
    if (index === 1) {
      this.setState({
        timelineAll: false,
        timelineUpcoming: true,
        timelineWeek: false,
        timelineMonth: false,
        timelineYear: false,
      });
    }
    if (index === 2) {
      this.setState({
        timelineAll: false,
        timelineUpcoming: false,
        timelineWeek: true,
        timelineMonth: false,
        timelineYear: false,
      });
    }
    if (index === 3) {
      this.setState({
        timelineAll: false,
        timelineUpcoming: false,
        timelineWeek: false,
        timelineMonth: true,
        timelineYear: false,
      });
    }
    if (index === 4) {
      this.setState({
        timelineAll: false,
        timelineUpcoming: false,
        timelineWeek: false,
        timelineMonth: false,
        timelineYear: true,
      });
    }
  };

  applyFilter = () => {
    this.props.confirmFilter(this.state);
    // console.log(this.state);
  };

  render() {
    return (
      <OverlayTrigger
        key="top"
        trigger="click"
        placement="bottom"
        rootClose
        // defaultShow //remove in production
        ref="overlay"
        overlay={
          <Popover
            style={{
              position: "absolute",
              backgroundColor: "black",
              minWidth: "60vw",
              marginRight: "20vw",
              padding: "0px",
            }}
          >
            <Popover.Content>
              <Container
                style={{
                  padding: "10px",
                  color: "white",
                  backgroundColor: "black",
                  border: "2px white solid",
                  borderRadius: "3px",
                }}
              >
                <Row className="filter_row">
                  <Col md={4} sm={8} className="filter_section">
                    <strong>STATUS</strong>
                    <p className="filter" onClick={() => this.statusTapped(0)}>
                      <img
                        alt="box"
                        src={this.state.statusInProgress ? box_x : box}
                      />
                      <span>IN PROGRESS</span>
                    </p>
                    <p className="filter" onClick={() => this.statusTapped(1)}>
                      <img
                        alt="box"
                        src={this.state.statusConfirmed ? box_x : box}
                      />
                      <span>CONFIRMED</span>
                    </p>
                    <p className="filter" onClick={() => this.statusTapped(2)}>
                      <img
                        alt="box"
                        src={this.state.statusCancelled ? box_x : box}
                      />
                      <span>CANCELLED</span>
                    </p>
                  </Col>
                  <Col md={4} sm={8} className="filter_section">
                    <strong>SERVICE</strong>
                    <p className="filter" onClick={() => this.requestTapped(0)}>
                      <img
                        alt="box"
                        src={this.state.requestFitness ? box_x : box}
                      />
                      <span>FITNESS</span>
                    </p>
                    <p className="filter" onClick={() => this.requestTapped(1)}>
                      <img
                        alt="box"
                        src={this.state.requestExperiences ? box_x : box}
                      />
                      <span>TICKETS</span>
                    </p>
                    <p className="filter" onClick={() => this.requestTapped(2)}>
                      <img
                        alt="box"
                        src={this.state.requestHome ? box_x : box}
                      />
                      <span>HOME</span>
                    </p>
                    <p className="filter" onClick={() => this.requestTapped(3)}>
                      <img
                        alt="box"
                        src={this.state.requestReservations ? box_x : box}
                      />
                      <span>RESERVATIONS</span>
                    </p>
                    <p className="filter" onClick={() => this.requestTapped(4)}>
                      <img
                        alt="box"
                        src={this.state.requestOther ? box_x : box}
                      />
                      <span>OTHER</span>
                    </p>
                  </Col>
                  <Col md={4} sm={8} className="filter_section">
                    <strong>SORT BY</strong>
                    <p className="filter" onClick={() => this.orderTapped(0)}>
                      <img
                        alt="box"
                        src={this.state.orderByNewToOld ? box_x : box}
                      />
                      <span>NEW TO OLD</span>
                    </p>
                    <p className="filter" onClick={() => this.orderTapped(1)}>
                      <img
                        alt="box"
                        src={this.state.orderByOldToNew ? box_x : box}
                      />
                      <span>OLD TO NEW</span>
                    </p>
                    <p className="filter" onClick={() => this.sortTapped(2)}>
                      <img
                        alt="box"
                        src={this.state.sortByAlphabetical ? box_x : box}
                      />
                      <span>A TO Z</span>
                    </p>
                  </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    // variant="none"
                    className="filter_button"
                    onClick={() => {
                      this.refs.overlay.hide();
                      this.resetFilter();
                    }}
                  >
                    RESET
                  </button>
                  <button
                    // variant="none"
                    className="filter_button"
                    onClick={() => {
                      this.refs.overlay.hide();
                      this.applyFilter();
                    }}
                  >
                    SAVE
                  </button>
                </Row>
              </Container>
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant="none">
          <img
            alt="filter"
            src={filter_icon}
            style={{ maxHeight: "50px", paddingBottom: "10px" }}
          />
        </Button>
      </OverlayTrigger>
    );
  }
}

export default HistoryFilter;
