/** @format */

import React, { PureComponent } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Col, Container, Form, Button } from "react-bootstrap";
import { addDays } from "date-fns";
import ReservationHotlist from "./ReservationHotlist";
import ReservationMap from "./ReservationMap";
import reservation from "../assets/img/squares/Reservations-Square.jpg";
import "../css/requestForm.css";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import { GLOBAL_DOMAIN } from "../actions/types";

class ReservationRequest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      typeModalSelectedValueConfirmed: "",
      partySizeModalSelectedValueConfirmed: "",
      neighborhoodModalSelectedValueConfirmed: "",
      occasionModalSelectedValueConfirmed: "",
      styleModalSelectedValueConfirmed: "",
      dateModalSelectedValueConfirmed: "", //new Date(),
      timeModalSelectedValueConfirmed: "", //new Date(),
      detailsValue: "",
      venueValue: "",
      selectedRestaurantIndex: -1,
      restaurants: [],
      imagePathArray: [],
      hotlist: true,
    };
  }
  componentDidMount = async () => {
    try {
      let checkHotListResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/data/reservations/hotlist`,
        {
          params: {
            token: localStorage.getItem("token"),
          },
        }
      );

      let hotListData = checkHotListResponse.data.records;
      let imagePathArray = [];

      hotListData.forEach((item, i) => {
        let matches =
          item.Restaurant_Image_for_App__c.match(/\bhttps?:\/\/\S+/gi);

        let urlString = matches[0].replace(/&amp;/g, "&");

        urlString = urlString.replace(/"/g, "");

        imagePathArray.push({ name: `pic${i}.png`, uri: urlString });
      });

      this.setState({ restaurants: hotListData, imagePathArray });
    } catch (error) {
      // If access token comes back expired
      try {
        let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);

        localStorage.setItem("token", response.data.access_token);
        let checkHotListResponse = await axios.get(
          `${GLOBAL_DOMAIN}/api/data/reservations/hotlist`,
          {
            params: {
              token: localStorage.getItem("token"),
            },
          }
        );

        let hotListData = checkHotListResponse.data.records;
        let imagePathArray = [];

        hotListData.forEach((item, i) => {
          let matches =
            item.Restaurant_Image_for_App__c.match(/\bhttps?:\/\/\S+/gi);

          let urlString = matches[0].replace(/&amp;/g, "&");

          urlString = urlString.replace(/"/g, "");

          imagePathArray.push({ name: `pic${i}.png`, uri: urlString });
        });

        this.setState({ restaurants: hotListData, imagePathArray });
      } catch (error) {
        console.log("Failed reauthenticate");
      }
    }
  };
  getDateArray = () => {
    let dateArray = [];
    let i = 0;

    while (i <= 93) {
      let nextDay = new Date(Date.now() + i * 24 * 60 * 60 * 1000);
      dateArray.push(nextDay);
      i++;
    }
    return dateArray;
  };

  onTypeModalConfirmValue = (value) => {
    this.setState({
      typeModalSelectedValueConfirmed: value,
    });
  };

  onTypeModalValueChange = (value) => {
    this.setState({ typeModalSelectedValue: value });
  };

  onPartySizeModalConfirmValue = (value) => {
    this.setState({
      partySizeModalSelectedValueConfirmed: value,
    });
  };

  onNeighborhoodModalConfirmValue = (value) => {
    this.setState({
      neighborhoodModalSelectedValueConfirmed: value,
    });
  };

  onNeighborhoodModalValueChange = (value) => {
    this.setState({ neighborhoodModalSelectedValue: value });
  };

  onOccasionModalConfirmValue = (value) => {
    this.setState({
      occasionModalSelectedValueConfirmed: value,
    });
  };

  onStyleModalConfirmValue = () => {
    this.setState({
      styleModalSelectedValueConfirmed: this.state.styleModalSelectedValue,
    });
  };

  onStyleModalValueChange = (value) => {
    this.setState({ styleModalSelectedValue: value });
  };

  onDateModalConfirmValue = (value) => {
    this.setState({
      dateModalSelectedValueConfirmed: value,
    });
  };

  onDateModalValueChange = (value) => {
    this.setState({ dateModalSelectedValue: value });
  };

  onTimeModalConfirmValue = (value) => {
    this.setState({
      timeModalSelectedValueConfirmed: value,
      timeModalSelectedValueConfirmedString: `${this.state.timeModalSelectedValue1}:${this.state.timeModalSelectedValue2} ${this.state.timeModalSelectedValue3}`,
    });
  };

  onDetailsTextChange = (text) => {
    this.setState({ detailsValue: text.toUpperCase() });
  };

  onVenueTextChange = (text) => {
    this.setState({ venueValue: text });
  };

  onNeighborhoodTextChange = (text) => {
    this.setState({ neighborhoodValue: text.toUpperCase() });
  };

  submitRequest = async () => {
    try {
      // let preferredDate = this.getDateArray()[
      //   this.state.dateModalSelectedValue
      // ];
      let preferredDate = this.state.dateModalSelectedValueConfirmed;

      //let preferredDateString = preferredDate.toISOString().substring(0, 10);

      let preferredDateDay = preferredDate.getDate();
      let preferredDateMonth = preferredDate.getMonth() + 1;
      let preferredDateYear = preferredDate.getFullYear();
      let preferredDateString =
        preferredDateYear + "-" + preferredDateMonth + "-" + preferredDateDay;

      const preferredTime = this.state.timeModalSelectedValueConfirmed;
      let preferredHours = preferredTime.getHours();
      let preferredMinutes = preferredTime.getMinutes();
      let AMorPM = "AM";
      if (preferredHours === 0) {
        preferredHours = 12;
      } else if (preferredHours === 12) {
        AMorPM = "PM";
      }
      if (preferredHours > 12) {
        preferredHours -= 12;
        AMorPM = "PM";
      }
      if (preferredMinutes < 10) {
        preferredMinutes = "0" + preferredMinutes;
      }

      let preferredTimeString =
        preferredHours + ":" + preferredMinutes + AMorPM;

      const cat = "Restaurants";
      const subject = "Restaurant Reservation Request";

      let subCat = "Restaurant";

      if (
        this.state.typeModalSelectedValueConfirmed.toUpperCase() === "NIGHTLIFE"
      ) {
        subCat = "Nightlife";
      }

      let detailsValueString = this.state.detailsValue;

      if (detailsValueString === "") {
        detailsValueString = "N/A";
      }

      let venueValueString = this.state.venueValue;

      if (venueValueString === "") {
        venueValueString = "N/A";
      }

      let neighborhoodValueString =
        this.state.neighborhoodModalSelectedValueConfirmed.toUpperCase();

      if (neighborhoodValueString === "") {
        neighborhoodValueString = "N/A";
      }

      let jsonObj = JSON.stringify({
        RecordTypeId: "0124A000001NSKk",
        Category__c: cat,
        Restaurant_or_Nightlife__c: subCat,
        Preferred_Date__c: preferredDateString,
        Preferred_Time__c: preferredTimeString,
        Party_Size__c: this.state.partySizeModalSelectedValue,
        Sub_Category__c: this.state.partySizeModalSelectedValue,
        Requested_Venue__c: venueValueString,
        Neighborhood_Preference__c: neighborhoodValueString,
        Occasion__c: this.state.occasionModalSelectedValue,
        Additional_Details__c: detailsValueString,
        Subject: subject,
        Origin: "Web Portal",
        ContactId: localStorage.getItem("contactId"),
      });

      console.log("jsonObj", jsonObj);
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/request/`, {
        params: {
          json: jsonObj,
          token: localStorage.getItem("token"),
        },
      });
      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("err", err);

      return { success: false, error: err };
    }
  };

  reAuthenticate = async () => {
    // If access token comes back expired
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);

      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      return { success: false, error: err };
    }
  };

  attemptSubmitRequest = async () => {
    // Validation Step

    if (
      !this.state.typeModalSelectedValueConfirmed ||
      !this.state.dateModalSelectedValueConfirmed ||
      !this.state.timeModalSelectedValueConfirmed ||
      !this.state.partySizeModalSelectedValueConfirmed
    ) {
      return;
      //   return Alert.alert(
      //     "Could Not Submit Request",
      //     "Please fill out all the required fields marked with a * before submitting request",
      //     [
      //       {
      //         text: "OK",
      //         onPress: () => console.log("OK Pressed on validation step")
      //       }
      //     ]
      //   );
    }

    let response = await this.submitRequest();

    // if response says 'Session expired or invalid' then attempt to reauthenticate
    // If that fails, user must log in again, if works, then attempt to submit request again
    //console.log('responseone', response);
    if (response.success) {
      //Request successful, navigate to Request Confirmation screen
      this.setState({
        typeModalSelectedValueConfirmed: "",
        partySizeModalSelectedValueConfirmed: "",
        neighborhoodModalSelectedValueConfirmed: "",
        occasionModalSelectedValueConfirmed: "",
        styleModalSelectedValueConfirmed: "",
        dateModalSelectedValueConfirmed: new Date(),
        timeModalSelectedValueConfirmed: new Date(),
        detailsValue: "",
        venueValue: "",
        selectedRestaurantIndex: -1,
      });
      this.props.history.push("/requests/submitted");

      // this.props.navigation.navigate("MakeRequestConfirmation", {
      //   barStyle: "light-content"
      // });
    } else {
      // Submit request failed: Try to get new Salesforce token

      let reauthResponse = await this.reAuthenticate();

      if (reauthResponse.success) {
        //New Token, add to redux and retry submitting request
        localStorage.setItem("token", reauthResponse.access_token);

        // this.props.addToken(reauthResponse.access_token);

        let responseTwo = await this.submitRequest();

        if (responseTwo.success) {
          // Second try request worked
          this.setState({
            typeModalSelectedValueConfirmed: "",
            partySizeModalSelectedValueConfirmed: "",
            neighborhoodModalSelectedValueConfirmed: "",
            occasionModalSelectedValueConfirmed: "",
            styleModalSelectedValueConfirmed: "",
            dateModalSelectedValueConfirmed: new Date(),
            timeModalSelectedValueConfirmed: new Date(),
            detailsValue: "",
            venueValue: "",
            selectedRestaurantIndex: -1,
          });
          this.props.history.push("/requests/submitted");

          // this.props.navigation.navigate("MakeRequestConfirmation", {
          //   barStyle: "light-content"
          // });
        } else {
          // Second try failed, send alert notification
          return;
          //   return Alert.alert(
          //     "Error Submitting Request",
          //     "Sorry, we didn't receive the request. Please try again in a couple of minutes.",
          //     [
          //       {
          //         text: "OK",
          //         onPress: () =>
          //           console.log("OK Pressed on Issue getting Salesforce token")
          //       }
          //     ]
          //   );
        }
      }
    }
  };

  render() {
    // console.log(this.state);
    const hasRestaurants = !!this.state.restaurants.length > 0;
    return (
      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <div className="request_title_div">
          <p className="request_title">RESERVATIONS</p>
        </div>
        <img
          src={reservation}
          style={{
            height: "270px",
            objectFit: "cover",
            width: "100%",
          }}
          alt="reservation-banner"
        ></img>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="res_title">HOT LIST</p>

          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                variant="none"
                className="res_toggle"
                onClick={() => this.setState({ hotlist: true })}
                style={
                  this.state.hotlist
                    ? {
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "0px",
                        width: "128px",
                        border: "black 4px solid",
                        height: "34px",
                      }
                    : {
                        backgroundColor: "white",
                        border: "black 4px solid",
                        color: "black",
                        borderRadius: "0px",
                        width: "128px",
                        height: "34px",
                      }
                }
              >
                GRID
              </button>
              <button
                variant="none"
                className="res_toggle"
                onClick={() => this.setState({ hotlist: false })}
                style={
                  !this.state.hotlist
                    ? {
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "0px",
                        width: "128px",
                        height: "34px",
                        border: "black 4px solid",
                      }
                    : {
                        backgroundColor: "white",
                        border: "black 4px solid",
                        color: "black",
                        borderRadius: "0px",
                        width: "128px",
                        height: "34px",
                      }
                }
              >
                MAP
              </button>
            </div>
            <br />
            {this.state.hotlist ? (
              <div style={!this.state.hotlist ? { display: "none" } : {}}>
                <ReservationHotlist
                  setRestaurant={this.onVenueTextChange}
                  setNeighborhood={this.onNeighborhoodModalConfirmValue}
                  restaurants={hasRestaurants ? this.state.restaurants : []}
                  imagePathArray={
                    hasRestaurants ? this.state.imagePathArray : []
                  }
                />
              </div>
            ) : (
              <div style={this.state.hotlist ? { display: "none" } : {}}>
                <ReservationMap
                  setRestaurant={this.onVenueTextChange}
                  setNeighborhood={this.onNeighborhoodModalConfirmValue}
                  restaurants={this.state.restaurants}
                  restaurantImages={this.state.imagePathArray}
                />
              </div>
            )}
          </Col>
          <Col style={{ marginTop: "50px" }} lg={5} md={12} sm={12} xs={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.attemptSubmitRequest();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Form.Group className="form_item">
                {/* <Form.Label>Category</Form.Label> */}
                <Form.Control
                  as="select"
                  placeholder="Category"
                  value={this.state.typeModalSelectedValueConfirmed}
                  onChange={(e) =>
                    this.onTypeModalConfirmValue.call(this, e.target.value)
                  }
                  required
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                  }}
                >
                  <option value="">CATEGORY*</option>
                  <option>Restaurant</option>
                  <option>Nightlife</option>
                </Form.Control>
              </Form.Group>
              {/* <p>Category</p>
              <Dropdown
                className="dropdown"
                options={["Restaurant", "Nightlife"]}
                value={this.state.typeModalSelectedValueConfirmed}
                placeholder="Choose a Category"
                onChange={e => this.onTypeModalConfirmValue(e.value)}
              /> */}
              <Form.Group className="form_item">
                {/* <Form.Label>Venue</Form.Label> */}
                <Form.Control
                  onChange={(e) => this.onVenueTextChange(e.target.value)}
                  value={this.state.venueValue}
                  placeholder="VENUE"
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                    textAlign: "center",
                  }}
                />
              </Form.Group>
              {/* <p>Venue</p>
              <input
                style={{ height: "30px", fontSize: "20px" }}
                value={this.state.venueValue}
                onChange={e => this.onVenueTextChange(e.target.value)}
              ></input> */}
              <Form.Group className="form_item">
                {/* <Form.Label>Preferred Neighborhood</Form.Label> */}
                <Form.Control
                  onChange={(e) =>
                    this.onNeighborhoodModalConfirmValue(e.target.value)
                  }
                  placeholder="PREFERRED NEIGHBORHOOD"
                  value={this.state.neighborhoodModalSelectedValueConfirmed}
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                    textAlign: "center",
                  }}
                />
              </Form.Group>
              {/* <p>Preferred Neighborhood</p>
              <input
                style={{ height: "30px", fontSize: "20px" }}
                value={this.state.neighborhoodModalSelectedValueConfirmed}
                onChange={e =>
                  this.onNeighborhoodModalConfirmValue(e.target.value)
                }
              ></input> */}
              <Form.Group className="form_item">
                {/* <Form.Label>Preferred Date</Form.Label>
                  <br /> */}
                <DatePicker
                  selected={this.state.dateModalSelectedValueConfirmed}
                  onChange={(e) => this.onDateModalConfirmValue(e)}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 90)}
                  placeholderText="PREFERRED DATE"
                />
              </Form.Group>
              <Form.Group className="form_item">
                <DatePicker
                  selected={
                    this.state.timeModalSelectedValueConfirmed
                      ? this.state.timeModalSelectedValueConfirmed.getTime()
                      : ""
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  // minTime={new Date()}
                  // maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  dateFormat="h:mm aa"
                  onChange={(e) => this.onTimeModalConfirmValue(e)}
                  placeholderText="PREFERRED TIME"
                ></DatePicker>
              </Form.Group>
              <Form.Group className="form_item">
                <Form.Control
                  as="select"
                  value={this.state.partySizeModalSelectedValueConfirmed}
                  onChange={(e) =>
                    this.onPartySizeModalConfirmValue.call(this, e.target.value)
                  }
                  required
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                  }}
                >
                  <option value="">PARTY OF *</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8+</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="form_item">
                {/* <Form.Label>Occasion</Form.Label> */}
                <Form.Control
                  as="select"
                  placeholder="Category"
                  value={this.state.occasionModalSelectedValueConfirmed}
                  onChange={(e) =>
                    this.onOccasionModalConfirmValue.call(this, e.target.value)
                  }
                  required
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                  }}
                >
                  <option value="">OCCASION</option>
                  <option>Buisness</option>
                  <option>Date Night</option>
                  <option>Special Occasion</option>
                  <option>Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="form_item">
                {/* <Form.Label>Additional Details</Form.Label> */}
                <Form.Control
                  onChange={(e) => this.onDetailsTextChange(e.target.value)}
                  placeholder="ADDITIONAL DETAILS"
                  style={{
                    height: "60px",
                    border: "2px #EFEFEF solid",
                    fontFamily: proximanova_light,
                    textAlign: "center",
                  }}
                />
              </Form.Group>
              <Button
                variant="dark"
                className="form_submit_button"
                type="submit"
                style={{ backgroundColor: "black" }}
              >
                <p style={{ fontSize: "24px", margin: "0px" }}>SUBMIT</p>
              </Button>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

export default withRouter(ReservationRequest);
