import React, { PureComponent } from "react";
import "../css/requestDashStyle.css";
import { Link } from "react-router-dom";
import tickets from "../assets/img/squares/Tickets-Square.jpg";
import fitness from "../assets/img/squares/Fitness-Square.jpg";
import reservations from "../assets/img/squares/Reservations-Square.jpg";
import home from "../assets/img/squares/Home-Square.jpg";
import other from "../assets/img/squares/Other-Square.jpg";
import contactUs from "../assets/img/squares/Call-Square.jpg";

import { Col, Image, Row } from "react-bootstrap";

import { connect } from "react-redux";
import proxinova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
class RequestsDash extends PureComponent {
  render() {
    return (
      <div
        style={{
          margin: "105px 76px 0px 66px",
        }}
      >
        <Row>
          <Col className="request-item" xs={6} md={4}>
            <Link
              to={"/requests/tickets"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  left: "-8px",
                }}
              >
                TICKETS
              </p>
              <Image src={tickets} alt="tickets" className="request-square" />
            </Link>
          </Col>
          <Col className="request-item" xs={6} md={4}>
            <Link
              to={"/requests/fitness"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  left: "-8px",
                }}
              >
                FITNESS
              </p>
              <Image src={fitness} alt="fitness" className="request-square" />
            </Link>
          </Col>
          <Col className="request-item" xs={6} md={4}>
            <Link
              to={"/requests/reservation"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  left: "-35px",
                  // fontSize: "1.5rem",
                  fontFamily: proxinova_light,
                }}
              >
                RESERVATIONS
              </p>
              <Image
                src={reservations}
                alt="reservations"
                className="request-square"
              />
            </Link>
          </Col>

          <Col className="request-item" xs={6} md={4}>
            <Link
              to={"/requests/home-services"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  left: "2px",
                }}
              >
                HOME
              </p>
              <Image
                src={home}
                alt="home services"
                className="request-square"
              />
            </Link>
          </Col>
          <Col className="request-item" xs={6} md={4}>
            <Link
              to={"/requests/other"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  // fontSize: "1.5rem"
                }}
              >
                OTHER
              </p>
              <Image src={other} alt="other" className="request-square" />
            </Link>
          </Col>
          <Col className="request-item" xs={6} md={4}>
            <a
              href="mailto:concierge@livunltd.com?subject=Concierge%20Request"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className="request-title"
                style={{
                  position: "absolute",
                  transform: "rotate(270deg)",
                  left: "-28px",
                }}
              >
                CONTACT US
              </p>
              <Image
                src={contactUs}
                alt="contact-us"
                className="request-square"
              />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(RequestsDash);
