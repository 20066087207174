/** @format */

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { Link, withRouter } from "react-router-dom";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import "../css/requestForm.css";
import "firebase/auth";
import axios from "axios";
import {
  onASEmailChange,
  onASPassword1Change,
  onASPassword2Change,
  attemptAccountSetup,
  onASNameChange,
  onASClearFields,
  onASError,
  addToken,
} from "../actions";
import { Form } from "react-bootstrap";
import { GLOBAL_DOMAIN } from "../actions/types";

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordClicked: false,
      forgotPasswordMessage: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.onASClearFields();
  }

  checkForEmail = async () => {
    try {
      console.log("this is the token", this.props.token);
      console.log(this.props.email);
      let checkEmailResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/auth/signup`,
        {
          params: {
            email: this.props.email,
            token: this.props.token,
          },
        }
      );

      console.log(checkEmailResponse);

      return {
        success: true,
        numberOfRecords: checkEmailResponse.data.totalSize,
        firstName: checkEmailResponse.data.records[0].Name,
        accountId: checkEmailResponse.data.records[0].AccountId,
        contactId: checkEmailResponse.data.records[0].Id,
      };
    } catch (err) {
      console.log("checkForEmail err", err);
      return { success: false, error: err };
    }
  };
  forgotPasswordPressed = async () => {
    if (this.props.email === "") {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Please enter your email.",
      });
      return;
    }

    try {
      await firebase.auth().sendPasswordResetEmail(this.props.email);
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Forgot Password email sent.",
      });
    } catch (err) {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Email not sent. Please verify your email.",
      });
    }
  };
  getSalesforceToken = async () => {
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      console.log(response);
      return { success: true, access_token: response.data };
    } catch (err) {
      console.log("getSalesforceToken err", err);
      return { success: false, error: err };
    }
  };

  attemptCreateAccount = async () => {
    this.props.attemptAccountSetup();
    // 1) Check if e-mail is null
    if (this.props.email === "") {
      this.props.onASError("Please enter a valid email.");
      return;
    }

    // 2) Check if e-mail is in Salesforce
    // 2a) First get token
    let response = await this.getSalesforceToken();
    console.log(response);
    // 2b) Token success? If not, post alert. If yes, add to reducer
    if (!response.success) {
      this.props.onASError("Unable to create profile");
      return;
    } else {
      this.props.addToken(response.access_token);
    }
    // 2c) Make call to check if e-mail is in system
    let emailCheckResponse = await this.checkForEmail();
    if (!emailCheckResponse.success) {
      this.props.onASError("The email entered is not in our system.");
      return;
    }
    // Call was successful
    else {
      // E-mail is NOT in salesforce
      if (emailCheckResponse.numberOfRecords === 0) {
        this.props.onASError("The email entered is not in our system.");
        return;
      } else {
        // this.props.onASNameChange(
        //   emailCheckResponse.firstName
        //   // emailCheckResponse.accountId,
        //   // emailCheckResponse.contactId
        // );
      }
    }

    // E-mail is in Salesforce, now check if pw1 or pw2 is null
    if (this.props.password1 === "" || this.props.password2 === "") {
      this.props.onASError("Password cannot be blank.");
      return;
    }

    // Check if pw1 matches pw2
    if (this.props.password1 !== this.props.password2) {
      this.props.onASError("Passwords must match.");
      return;
    }
    // Try and create user in firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(this.props.email, this.props.password1)
      .then(() =>
        firebase
          .auth()
          .currentUser.updateProfile({ displayName: this.props.displayName })
          .then(() => {
            firebase.auth().currentUser.sendEmailVerification();
            localStorage.setItem("signedUp", true);
            this.props.onASNameChange(
              emailCheckResponse.firstName
              // emailCheckResponse.accountId,
              // emailCheckResponse.contactId
            );
            // return <Redirect to='/login' />;
          })
          .catch((error) => console.log("emailerror", error))
      )
      .catch((error) => this.props.onASError(error.message));
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          className="welcome"
          style={{
            marginTop: "30px",
            fontSize: "36px",
            letterSpacing: 0.4,
          }}
        >
          WELCOME
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.attemptCreateAccount();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: 200,
            }}
          >
            <Form.Group className="form_item">
              <Form.Control
                type="email"
                placeholder="EMAIL"
                value={this.props.email}
                onChange={(text) =>
                  this.props.onASEmailChange(text.target.value)
                }
                style={{
                  textAlign: "center",
                  minWidth: "300px",
                  padding: "10px",
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                type="password"
                placeholder="PASSWORD"
                value={this.props.password1}
                onChange={(text) =>
                  this.props.onASPassword1Change(text.target.value)
                }
                style={{
                  textAlign: "center",
                  minWidth: "300px",
                  // width: "40vw",
                  padding: "10px",
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                type="password"
                placeholder="CONFIRM PASSWORD"
                value={this.props.password2}
                onChange={(text) =>
                  this.props.onASPassword2Change(text.target.value)
                }
                style={{
                  textAlign: "center",
                  minWidth: "300px",
                  // width: "40vw",
                  padding: "10px",
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              ></Form.Control>
            </Form.Group>

            {this.props.accountSetupError !== "" && (
              <div
                style={{
                  color: "#f34",
                  marginTop: 10,
                  paddingLeft: 10,
                  marginBottom: 10,
                }}
              >
                {this.props.accountSetupError}
              </div>
            )}

            {this.props.loading && <div> signing in...</div>}
            <button
              className="login_button"
              style={{
                marginTop: "10px",
                color: "white",
                fontSize: "24px",
                backgroundColor: "black",
                padding: "10px",
                width: "190px",
                letterSpacing: "2.5px",
              }}
              type="Submit"
            >
              SUBMIT
            </button>
          </div>
        </Form>

        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50vw",
            marginBottom: "30px",
          }}
        >
          <Link
            to={"/login"}
            style={{
              color: "black",
              textDecoration: "none",
              fontFamily: proximanova_light,
            }}
          >
            LOGIN{" "}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.account.email,
  password1: state.account.password1,
  password2: state.account.password2,
  token: state.auth.token,
  displayName: state.account.displayName,
  accountSetupError: state.account.error,
  loading: state.account.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    onASEmailChange,
    onASPassword1Change,
    onASPassword2Change,
    onASNameChange,
    attemptAccountSetup,
    onASClearFields,
    addToken,
    onASError,
  })(SignUp)
);
