import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../css/submitted.css";
class RequestSubmitted extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container
        style={{
          marginTop: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p className="thanks">THANK YOU</p>
        <p className="center">
          YOUR REQUEST HAS BEEN RECIEVED.{" "}
          <p className="center">
            PLEASE KEEP AN EYE ON YOUR INBOX FOR A RESPONSE FROM YOUR CONCIERGE.
          </p>
        </p>
        <Link to="/requests" className="button">
          SUBMIT ANOTHER
        </Link>
        <Link to="/history" className="button">
          REQUEST HISTORY
        </Link>
      </Container>
    );
  }
}

export default RequestSubmitted;
