import React, { PureComponent } from "react";
import { Table, Image, Container } from "react-bootstrap";
import "../css/requestForm.css";

import left_arrow from "../assets/buttons/left-arrow.png";
import right_arrow from "../assets/buttons/right-arrow.png";
class ReservationHotlist extends PureComponent {
  constructor(props) {
    super(props);
    this.props.setRestaurant.bind(this);
  }

  render() {
    return (
      <div>
        {/* <h2 style={{ textAlign: "center" }}>Hot List</h2> */}
        {this.props.restaurants.length !== 0 ? (
          <Container style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => {
                let table = document.getElementsByClassName("table-responsive");
                table[0].scrollBy(-200, 0);
              }}
            >
              <img src={left_arrow} className="arrow_img" alt="left_arrow" />
            </div>
            <Table
              className="hotlist_table"
              // borderless={false}
              responsive={true}
              // hover={true}
              // size="sm"
              // style={{ width: "600px" }}
            >
              <tbody>
                <tr>
                  {this.props.restaurants.map((item, index) => {
                    return (
                      <td
                        key={`restaurant-${item.Name}`}
                        style={{
                          backgroundColor: "Black",
                          borderRight: "40px white Solid",
                        }}
                      >
                        <Image
                          src={`${this.props.imagePathArray[index].uri}`}
                          style={{
                            height: "200px",
                            objectFit: "cover",
                            width: "300px",
                            // borderRadius: "100px"
                          }}
                          onClick={() => {
                            this.props.setRestaurant(item.Name);
                            this.props.setNeighborhood(item.Neighborhood__c);
                          }}
                        />
                        <p
                          className="restaurant_name"
                          style={{
                            textAlign: "center",
                            color: "White",
                            marginTop: "1rem",
                            marginBottom: "5px",
                            fontWeight: 700,
                            letterSpacing: "2px",
                          }}
                        >
                          {item.Name.toUpperCase()}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>{" "}
            <div
              onClick={() => {
                let table = document.getElementsByClassName("table-responsive");
                table[0].scrollBy(200, 0);
              }}
            >
              <img src={right_arrow} className="arrow_img" alt="right_arrow" />
            </div>
          </Container>
        ) : (
          <div>
            <h2>Retrieving Hot List</h2>
          </div>
        )}
      </div>
    );
  }
}

export default ReservationHotlist;
