/** @format */

import React, { PureComponent } from "react";
import "react-dropdown/style.css";
import "../css/requestForm.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { addDays } from "date-fns";
import { withRouter } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import tickets from "../assets/img/banners/Tickets-Banner.jpg";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import { GLOBAL_DOMAIN } from "../actions/types";

class TicketsRequest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      typeModalSelectedValueConfirmed: "",
      seatingModalSelectedValueConfirmed: "",
      numberOfTixModalSelectedValueConfirmed: "",
      dateModalSelectedValueConfirmed: "",
      timeModalSelectedValueConfirmed: "",
      eventTitleValue: "",
      detailsValue: "",
    };
  }

  onTypeModalConfirmValue = (value) => {
    this.setState({
      typeModalSelectedValueConfirmed: value,
    });
  };

  onDateModalConfirmValue = (value) => {
    this.setState({
      dateModalSelectedValueConfirmed: value,
    });
  };

  onTimeModalConfirmValue = (value) => {
    this.setState({
      timeModalSelectedValueConfirmed: value,
    });
  };

  onSeatingModalConfirmValue = (value) => {
    this.setState({
      seatingModalSelectedValueConfirmed: value,
    });
  };

  onNumberOfTixModalConfirmValue = (value) => {
    this.setState({
      numberOfTixModalSelectedValueConfirmed: value,
    });
  };

  onEventTitleTextChange = (text) => {
    this.setState({ eventTitleValue: text.toUpperCase() });
  };

  onDetailsTextChange = (text) => {
    this.setState({ detailsValue: text.toUpperCase() });
  };

  submitRequest = async () => {
    try {
      let subCat = "Other";
      if (this.state.typeModalSelectedValueConfirmed === "CONCERT") {
        subCat = "Concert";
      } else if (
        this.state.typeModalSelectedValueConfirmed === "SPECIAL EVENT"
      ) {
        subCat = "Special Event";
      } else if (this.state.typeModalSelectedValueConfirmed === "SPORTS") {
        subCat = "Sports";
      } else if (this.state.typeModalSelectedValueConfirmed === "THEATRE") {
        subCat = "Theatre";
      } else if (this.state.typeModalSelectedValueConfirmed === "TOURS") {
        subCat = "Tours";
      } else if (this.state.typeModalSelectedValueConfirmed === "OTHER") {
        subCat = "Other";
      }

      const preferredDate = this.state.dateModalSelectedValueConfirmed;
      let preferredDateDay = preferredDate.getDate();
      let preferredDateMonth = preferredDate.getMonth() + 1;
      let preferredDateYear = preferredDate.getFullYear();
      let preferredDateString =
        preferredDateYear + "-" + preferredDateMonth + "-" + preferredDateDay;
      let preferredSeating = "NONE";

      const preferredTime = this.state.timeModalSelectedValueConfirmed;
      let preferredHours = preferredTime.getHours();
      let preferredMinutes = preferredTime.getMinutes();
      let AMorPM = "AM";
      if (preferredHours === 0) {
        preferredHours = 12;
      } else if (preferredHours === 12) {
        AMorPM = "PM";
      }
      if (preferredHours > 12) {
        preferredHours -= 12;
        AMorPM = "PM";
      }
      if (preferredMinutes < 10) {
        preferredMinutes = "0" + preferredMinutes;
      }

      let preferredTimeString =
        preferredHours + ":" + preferredMinutes + AMorPM;
      if (
        this.state.seatingModalSelectedValueConfirmed !== "Preferred seating..."
      ) {
        preferredSeating = this.state.seatingModalSelectedValueConfirmed;
      }

      if (preferredSeating === "NONE") {
        preferredSeating = "None";
      } else if (preferredSeating === "STANDARD") {
        preferredSeating = "Standard";
      } else if (preferredSeating === "PREMIUM") {
        preferredSeating = "Premium";
      }

      let numberOfTix = 1;

      if (
        this.state.numberOfTixModalSelectedValueConfirmed !==
        "Number of tickets needed..."
      ) {
        numberOfTix = this.state.numberOfTixModalSelectedValueConfirmed;
      }

      let detailsValueString = this.state.detailsValue;

      if (detailsValueString === "") {
        detailsValueString = "N/A";
      }

      let eventTitleValueString = this.state.eventTitleValue;

      if (eventTitleValueString === "") {
        eventTitleValueString = "N/A";
      }

      const cat = "Experiences";
      const subject = "Tickets Request";

      let jsonObj = JSON.stringify({
        RecordTypeId: "0124A000001NSKm",
        Category__c: cat,
        Sub_Category__c: subCat,
        Name_the_Experience__c: eventTitleValueString,
        Preferred_Date__c: preferredDateString,
        Preferred_Time__c: preferredTimeString,
        of_Adult_Tix__c: numberOfTix,
        Seating_Preference__c: preferredSeating,
        Additional_Details__c: detailsValueString,
        Subject: subject,
        Origin: "Web Portal",
        ContactId: localStorage.getItem("contactId"),
      });
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/request/`, {
        params: {
          json: jsonObj,
          token: localStorage.getItem("token"),
        },
      });
      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("Submit Tickets Request err", err);

      return { success: false, error: err };
    }
  };

  reAuthenticate = async () => {
    // If access token comes back expired
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data.access_token };
    } catch (err) {
      console.log("getSalesforceToken err on login", err);
      return { success: false, error: err };
    }
  };

  attemptSubmitRequest = async () => {
    // Validation Step

    if (
      !this.state.typeModalSelectedValueConfirmed ||
      !this.state.dateModalSelectedValueConfirmed ||
      !this.state.timeModalSelectedValueConfirmed ||
      !this.state.numberOfTixModalSelectedValueConfirmed ||
      !this.state.seatingModalSelectedValueConfirmed
    ) {
      return;
      //   return Alert.alert(
      //     "Could Not Submit Request",
      //     "Please fill out all the required fields marked with a * before submitting request",
      //     [
      //       {
      //         text: "OK",
      //         onPress: () => console.log("OK Pressed on validation step")
      //       }
      //     ]
      //   );
    }

    let response = await this.submitRequest();

    // if response says 'Session expired or invalid' then attempt to reauthenticate
    // If that fails, user must log in again, if works, then attempt to submit request again
    // console.log("response", response);
    if (response.success) {
      //Request successful, navigate to Request Confirmation screen

      this.setState({
        typeModalSelectedValueConfirmed: "",
        seatingModalSelectedValueConfirmed: "",
        numberOfTixModalSelectedValueConfirmed: "",
        dateModalSelectedValueConfirmed: new Date(),
        timeModalSelectedValueConfirmed: new Date(),
        eventTitleValue: "",
        detailsValue: "",
      });
      this.props.history.push("/requests/submitted");
      //   return <Redirect to='/promos' />;
      //   console.log("here");
      //   confirm();
      //   this.props.navigation.navigate("MakeRequestConfirmation", {
      //     barStyle: "light-content"
      //   });
    } else {
      // Submit request failed: Try to get new Salesforce token

      let reauthResponse = await this.reAuthenticate();

      if (reauthResponse.success) {
        //New Token, add to redux and retry submitting request

        // this.props.addToken(reauthResponse.access_token);
        localStorage.setItem("token", reauthResponse.access_token);
        let responseTwo = await this.submitRequest();

        if (responseTwo.success) {
          // Second try request worked

          this.setState({
            typeModalSelectedValueConfirmed: "",
            seatingModalSelectedValueConfirmed: "",
            numberOfTixModalSelectedValueConfirmed: "",
            dateModalSelectedValueConfirmed: new Date(),
            timeModalSelectedValueConfirmed: new Date(),
            eventTitleValue: "",
            detailsValue: "",
          });
          this.props.history.push("/requests/submitted");

          //   this.props.navigation.navigate("MakeRequestConfirmation", {
          //     barStyle: "light-content"
          //   });
        } else {
          // Second try failed, send alert notification
          return;
          //   return Alert.alert(
          //     "Error Submitting Request",
          //     "Sorry, we didn't receive the request. Please try again in a couple of minutes.",
          //     [
          //       {
          //         text: "OK",
          //         onPress: () =>
          //           console.log("OK Pressed on Issue getting Salesforce token")
          //       }
          //     ]
          //   );
        }
      }
    }
  };

  render() {
    return (
      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <div className="request_title_div">
          <p className="request_title">TICKETS</p>
        </div>
        <img
          src={tickets}
          style={{
            height: "270px",
            objectFit: "cover",
            width: "100%",
          }}
          alt="tickets-banner"
        ></img>
        <Container>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.attemptSubmitRequest();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <br />
            {/* <p style={{ textAlign: "center", fontSize: 40, fontWeight: 600 }}>
              Tickets
            </p> */}
            <Form.Group className="form_item">
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.typeModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onTypeModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  fontFamily: proximanova_light,
                }}
              >
                <option value="">CATEGORY*</option>
                <option>Concert</option>
                <option>Special Event</option>
                <option>Sports</option>
                <option>Travel</option>
                <option>Theatre</option>
                <option>Tours</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                onChange={(e) => this.onEventTitleTextChange(e.target.value)}
                placeholder="EVENT"
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  textAlign: "center",
                  fontFamily: proximanova_light,
                }}
              />
            </Form.Group>
            <Form.Group className="form_item">
              <DatePicker
                selected={this.state.dateModalSelectedValueConfirmed}
                placeholderText="PREFERRED DATE"
                onChange={(e) => this.onDateModalConfirmValue(e)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 90)}
              />
            </Form.Group>
            <Form.Group className="form_item">
              <DatePicker
                selected={
                  this.state.timeModalSelectedValueConfirmed
                    ? this.state.timeModalSelectedValueConfirmed.getTime()
                    : ""
                }
                showTimeSelect
                showTimeSelectOnly
                placeholderText="PREFERRED TIME"
                // minTime={new Date()}
                // maxTime={setHours(setMinutes(new Date(), 30), 23)}
                dateFormat="h:mm aa"
                onChange={(e) => this.onTimeModalConfirmValue(e)}
              ></DatePicker>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.numberOfTixModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onNumberOfTixModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  textAlign: "center",
                  fontFamily: proximanova_light,
                }}
              >
                <option value="">NO. OF TICKETS</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8+</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                as="select"
                placeholder="Category"
                value={this.state.seatingModalSelectedValueConfirmed}
                onChange={(e) =>
                  this.onSeatingModalConfirmValue.call(this, e.target.value)
                }
                required
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  textAlign: "center",
                }}
              >
                <option value="">SEATING PREFERENCE</option>
                <option>None</option>
                <option>Standard</option>
                <option>Premium</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form_item">
              <Form.Control
                onChange={(e) => this.onDetailsTextChange(e.target.value)}
                placeholder="ADDITIONAL DETAILS"
                style={{
                  height: "60px",
                  border: "2px #EFEFEF solid",
                  textAlign: "center",
                }}
              />
            </Form.Group>
            <Button
              variant="dark"
              className="form_submit_button"
              type="submit"
              style={{ backgroundColor: "black" }}
            >
              <p style={{ fontSize: "24px", margin: "0px" }}>SUBMIT</p>
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}

export default withRouter(TicketsRequest);
