import React, { PureComponent } from "react";

class HistoryDetailsModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderFields = (request, cat, subcat, status) => {
    let vendorText = "TBD";

    if (request.Vendor_2__c) {
      vendorText = request.Vendor_2__r.Name;
    }

    switch (cat) {
      case "FITNESS":
        return (
          <div>
            <div>
              <p>
                PREFERRED GENDER:{" "}
                <span className={"proxima_bold"}>
                  {request.Preferred_Gender__c
                    ? request.Preferred_Gender__c.toUpperCase()
                    : "NONE"}
                </span>
              </p>
            </div>
            <div>
              <p>
                PREFERRED TRAINER:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {request.Preferred_Trainer__c
                    ? request.Preferred_Trainer__c.toUpperCase()
                    : "NONE"}
                </span>
              </p>
            </div>
            {subcat === "MASSAGE" && status === "CONFIRMED" ? (
              <div>
                <p>
                  MASSEUSE NAME:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {vendorText ? vendorText.toUpperCase() : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}
            {(subcat === "PILATES" ||
              subcat === "YOGA" ||
              subcat === "SWIM" ||
              subcat === "PERSONAL TRAINING") &&
            status === "CONFIRMED" ? (
              <div>
                <p>
                  TRAINER NAME:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {vendorText ? vendorText.toUpperCase() : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}
            <div>
              <p>
                ADDITIONAL DETAILS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Additional_Details__c
                    ? request.Additional_Details__c.toUpperCase()
                    : "N/A"}
                </span>
              </p>
            </div>
          </div>
        );
      case "TICKETS":
        return (
          <div>
            <div>
              <p>
                NO. OF TICKETS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Total_of_Tix__c
                    ? request.Total_of_Tix__c.toString().toUpperCase()
                    : "TBD"}
                </span>
              </p>
            </div>
            <div>
              <p>
                EVENT:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Name_the_Experience__c
                    ? request.Name_the_Experience__c.toUpperCase()
                    : "TBD"}
                </span>
              </p>
            </div>
            <div>
              <p>
                SEATING PREFERENCE:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Seating_Preference__c
                    ? request.Seating_Preference__c.toUpperCase()
                    : "N/A"}
                </span>
              </p>
            </div>
            {status === "CONFIRMED" ? (
              <div>
                <p>
                  ORDER CONFIRMATION NUMBER:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {request.Confirmationnumber__c
                      ? request.Confirmationnumber__c.toUpperCase()
                      : "N/A"}
                  </span>
                </p>
              </div>
            ) : null}
            {status === "CONFIRMED" ? (
              <div>
                <p>
                  DELIVERY METHOD:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {request.Delivery_Method__c
                      ? request.Delivery_Method__c.toUpperCase()
                      : "N/A"}
                  </span>
                </p>
              </div>
            ) : null}
            <div>
              <p>
                ADDITIONAL DETAILS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Additional_Details__c
                    ? request.Additional_Details__c.toUpperCase()
                    : "N/A"}
                </span>
              </p>
            </div>
          </div>
        );
      case "RESERVATIONS":
        return (
          <div>
            <div>
              <p>
                PARTY OF:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Party_Size__c
                    ? request.Party_Size__c.toUpperCase()
                    : "TBD"}
                </span>
              </p>
            </div>

            <div>
              <p>
                VENDOR:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {vendorText
                    ? vendorText.toUpperCase()
                    : request.Requested_Venue__c
                    ? request.Requested_Venue__c.toUpperCase()
                    : "TBD"}
                </span>
              </p>
            </div>
            {status === "CONFIRMED" ? (
              <div>
                <p>
                  RESERVATION NAME:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {request.Reservation_Name__c
                      ? request.Reservation_Name__c.toUpperCase()
                      : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}
            {status === "CONFIRMED" ? (
              <div>
                <p>
                  RESERVATION PHONE NUMBER:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {request.Reservation_Phone__c
                      ? request.Reservation_Phone__c.toUpperCase()
                      : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}
            <div>
              <p>
                ADDITIONAL DETAILS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Additional_Details__c
                    ? request.Additional_Details__c.toUpperCase()
                    : "N/A"}
                </span>
              </p>
            </div>
          </div>
        );
      case "HOME":
        return (
          <div>
            {status === "CONFIRMED" ? (
              <div>
                <p>
                  VENDOR:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {vendorText ? vendorText.toUpperCase() : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}

            <div>
              <p>
                ADDITIONAL DETAILS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Additional_Details__c
                    ? request.Additional_Details__c.toUpperCase()
                    : "N/A"}
                </span>
              </p>
            </div>
          </div>
        );
      default:
        return (
          <div>
            {status === "CONFIRMED" &&
            (subcat.toUpperCase() === "GIFTING" ||
              subcat.toUpperCase() === "FLOWERS" ||
              subcat.toUpperCase() === "DRY CLEANING" ||
              subcat.toUpperCase() === "PET SERVICES" ||
              subcat.toUpperCase() === "EVENT PLANNING") ? (
              <div>
                <p>
                  VENDOR:{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    {request.Vendor_2__r
                      ? request.Vendor_2__r.Name.toUpperCase()
                      : "TBD"}
                  </span>
                </p>
              </div>
            ) : null}
            {subcat.toUpperCase() === "TRAVEL" ? (
              <div>
                <div>
                  <p>
                    LOCATION:{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      {request.Travel_Destination__c
                        ? request.Travel_Destination__c.toUpperCase()
                        : "TBD"}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    TRAVEL DATES:{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      {request.Travel_Dates__c
                        ? request.Travel_Dates__c.toUpperCase()
                        : "TBD"}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    TRAVELING WITH KIDS:{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      {request.Traveling_with_Kids__c ? "YES" : "NO"}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    TRAVELING WITH PETS:{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      {request.Traveling_with_Pets__c ? "YES" : "NO"}
                    </span>
                  </p>
                </div>
              </div>
            ) : null}
            <div>
              <p>
                ADDITIONAL DETAILS:{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {request.Additional_Details__c
                    ? request.Additional_Details__c.toUpperCase()
                    : "N/A"}{" "}
                </span>
              </p>
            </div>
          </div>
        );
    }
  };
  parseISOString = (s) => {
    if (s) {
      let b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }
  };
  renderDateTime = (request) => {
    let dateTitle;
    let date1;
    let date0;

    let timeTitle;
    let time1;

    let timeString;
    let timeHours;
    let timeMinutes;
    let timeAMPM = "PM";

    if (request.Appointment_Date_Time__c) {
      date1 = new Date(this.parseISOString(request.Appointment_Date_Time__c))
        .toString()
        .slice(0, 10)
        .toUpperCase();
      dateTitle = "CONFIRMED DATE";

      timeString = new Date(
        this.parseISOString(request.Appointment_Date_Time__c)
      )
        .toString()
        .slice(16, 21)
        .toUpperCase();

      timeHours = parseInt(timeString.slice(0, 2));

      if (timeHours < 12) {
        timeAMPM = "AM";
      }
      if (timeHours === 0) {
        timeHours = 12;
      }
      if (timeHours > 12) {
        timeHours = timeHours - 12;
      }

      timeMinutes = timeString.slice(3, 5);

      time1 = `${timeHours}:${timeMinutes} ${timeAMPM}`;
      timeTitle = "CONFIRMED TIME";
    } else if (request.Preferred_Date__c) {
      date0 = new Date(request.Preferred_Date__c);
      date0.setDate(date0.getDate() + 1);
      date1 = date0.toString().slice(0, 10).toUpperCase();
      //date1 = (new Date(request.Preferred_Date__c)).toString().slice(0,10).toUpperCase();
      dateTitle = "PREFERRED DATE";

      time1 = request.Preferred_Time__c
        ? request.Preferred_Time__c.toUpperCase()
        : "N/A";
      timeTitle = "PREFERRED TIME";
    } else {
      date1 = new Date(this.parseISOString(request.CreatedDate))
        .toString()
        .slice(0, 10)
        .toUpperCase();
      dateTitle = "CREATED DATE";

      timeString = new Date(this.parseISOString(request.CreatedDate))
        .toString()
        .slice(16, 20)
        .toUpperCase();

      timeHours = parseInt(timeString.slice(0, 2));

      if (timeHours < 12) {
        timeAMPM = "AM";
      }
      if (timeHours === 0) {
        timeHours = 12;
      }
      if (timeHours > 12) {
        timeHours = timeHours - 12;
      }

      timeMinutes = timeString.slice(3, 5);

      time1 = `${timeHours}:${timeMinutes} ${timeAMPM}`;
      timeTitle = "CREATED TIME";
    }

    return (
      <div>
        <div>
          <p>
            {dateTitle}:{date1}
          </p>
        </div>
        <div>
          <p>
            {timeTitle}: <span>{time1}</span>
          </p>
        </div>
      </div>
    );
  };
  render() {
    let { request } = this.props;

    let status = "IN PROGRESS";
    // let date1;
    let dateTestFinal;

    if (request.Appointment_Date_Time__c) {
      dateTestFinal = new Date(
        this.parseISOString(request.Appointment_Date_Time__c)
      );
    } else if (request.Preferred_Date__c) {
      dateTestFinal = new Date(request.Preferred_Date__c);
    } else {
      dateTestFinal = new Date(this.parseISOString(request.CreatedDate));
    }

    if (request.Status === "Cancelled") {
      status = "CANCELLED";
    }
    if (request.Fulfilled_and_Final__c) {
      status = "CONFIRMED";
    }
    let todayDate = new Date();

    if (request.Status !== "Cancelled" && dateTestFinal < todayDate) {
      status = "CONFIRMED";
    }

    let subcat = request.Sub_Category__c
      ? request.Sub_Category__c.toUpperCase()
      : "";

    let cat = request.Category__c ? request.Category__c.toUpperCase() : "OTHER";

    if (cat.toUpperCase() === "FITNESS/WELLNESS") {
      cat = "FITNESS";
    }

    if (cat.toUpperCase() === "EXPERIENCES") {
      cat = "TICKETS";
    }

    if (cat.toUpperCase() === "RESTAURANTS") {
      cat = "RESERVATIONS";
      subcat = request.Restaurant_or_Nightlife__c
        ? request.Restaurant_or_Nightlife__c.toUpperCase()
        : "RESERVATIONS";
    }

    if (cat === "OTHER") {
      subcat = request.Subject.toUpperCase();
    }

    return (
      <div>
        {/* <p>STATUS:{status}</p> */}
        <p>
          CATEGORY: <span style={{ fontWeight: "bolder" }}>{subcat} </span>
        </p>
        {/* {this.renderDateTime(request)} */}
        {this.renderFields(request, cat, subcat, status)}
      </div>
    );
  }
}

export default HistoryDetailsModal;
