/** @format */

import React, { PureComponent } from "react";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import "../css/style.css";
import "../css/requestForm.css";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";

import {
  addToken,
  onLIEmailChange,
  onLIPasswordChange,
  attemptLogin,
  // onLIClearFields,
  onLIError,
  onASNameChange,
} from "../actions";

import "../css/style.css";
import { Form } from "react-bootstrap";
import { GLOBAL_DOMAIN } from "../actions/types";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordClicked: false,
      forgotPasswordMessage: "",
    };
  }

  async UNSAFE_componentWillMount() {
    const displayName = localStorage.getItem("displayName");
    const accountId = localStorage.getItem("accountId") || "";
    const contactId = localStorage.getItem("contactId");
    const region = localStorage.getItem("region");

    if (contactId !== "undefined") {
      //   this.props.onLIEmailChange(email)//newAsyncStore[0][1]);
      //   this.props.onLIPasswordChange(password)//newAsyncStore[1][1]);
      //   this.props.addToken(token)//newAsyncStore[2][1]);
      this.props.onASNameChange(displayName, accountId, contactId, region);
    }
  }
  checkForEmail = async () => {
    try {
      let checkEmailResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/auth/email`,
        {
          params: {
            email: this.props.email,
            token: this.props.token,
          },
        }
      );

      localStorage.removeItem("signedUp");
      return {
        success: true,
        numberOfRecords: checkEmailResponse.data.totalSize,
        firstName: checkEmailResponse.data.records[0].Name,
        accountId: checkEmailResponse.data.records[0].AccountId,
        contactId: checkEmailResponse.data.records[0].Id,
        accountRegion: checkEmailResponse.data.records[0]?.Account?.Region__c,
      };
    } catch (err) {
      // console.log("checkForEmail err");
      return { success: false, error: err };
    }
  };
  getSalesforceToken = async () => {
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data };
    } catch (err) {
      console.log("getSalesforceToken err on login");
      return { success: false, error: err };
    }
  };
  attemptLogin = async () => {
    this.setState({ forgotPasswordClicked: false });
    if (this.props.email === "" || this.props.password === "") {
      this.props.onLIError("Please enter a valid email and/or password.");
      return;
    }
    // 1) make sure you have a Salesforce token
    // console.log("attempting login");
    if (this.props.token === "") {
      try {
        let response = await this.getSalesforceToken();
        // 1a) Token success? If not, post alert. If yes, add to reducer
        // console.log("response", response);
        if (!response.success) {
          this.props.onLIError("Error Verifying Account");
          // console.log("Error Creating Account");
          return;
        } else {
          this.props.addToken(response.access_token);
        }
      } catch (err) {
        this.props.onLIError("Error Logging In");
        console.log("Error Logging in attemptLogin");
        return;
      }
    }

    // 2) login to firebase
    try {
      let user = await firebase
        .auth()
        .signInWithEmailAndPassword(this.props.email, this.props.password);
      // console.log("THIS IS USER", user);
      // 3) check if user has verified their email address
      if (!user.user.emailVerified) {
        // console.log("error loggin in - email not verified");
        this.props.onLIError("Email not verified");

        return;
      }
    } catch (error) {
      this.props.onLIError("The username and/or password are incorrect.");
      return;
    }
    // 4) get salesforce accountId
    try {
      let emailCheckResponse = await this.checkForEmail();
      localStorage.setItem("token", this.props.token);
      localStorage.setItem("accountId", emailCheckResponse.accountId);
      localStorage.setItem("contactId", emailCheckResponse.contactId);
      localStorage.setItem("region", emailCheckResponse?.accountRegion);

      this.props.onASNameChange(
        emailCheckResponse.firstName,
        emailCheckResponse.accountId,
        emailCheckResponse.contactId,
        emailCheckResponse.accountRegion
      );
    } catch (err) {
      this.props.onLIError("Please contact support@livunltd.com");

      console.log("Error logging in");
      return;
    }
  };

  forgotPasswordPressed = async () => {
    if (this.props.email === "") {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Please enter your email.",
      });
      return;
    }

    try {
      await firebase.auth().sendPasswordResetEmail(this.props.email);
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Forgot Password email sent.",
      });
    } catch (err) {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Email not sent. Please verify your email.",
      });
    }
  };

  render() {
    return (
      <div style={{ marginTop: "50px" }}>
        {!this.props.isLoggedIn && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.attemptLogin();
              this.attemptLogin();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Form.Group className="form_item">
                <Form.Control
                  type="email"
                  placeholder="EMAIL"
                  value={this.props.email}
                  onChange={(text) => {
                    this.setState({
                      forgotPasswordClicked: false,
                      forgotPasswordMessage: "",
                    });

                    this.props.onLIEmailChange(text.target.value);
                  }}
                  style={{
                    textAlign: "center",
                    minWidth: "300px",
                    padding: "10px",
                    height: "60px",
                    border: "2px #EFEFEF solid",
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="form_item">
                <Form.Control
                  type="password"
                  placeholder="PASSWORD"
                  value={this.props.password}
                  onChange={(text) => {
                    this.setState({
                      forgotPasswordClicked: false,
                      forgotPasswordMessage: "",
                    });
                    this.props.onLIPasswordChange(text.target.value);
                  }}
                  style={{
                    textAlign: "center",
                    minWidth: "300px",
                    padding: "10px",
                    height: "60px",
                    border: "2px #EFEFEF solid",
                  }}
                ></Form.Control>
              </Form.Group>
              {this.props.loginError !== "" && (
                <div
                  style={{
                    color: "#f34",
                    marginTop: 10,
                    paddingLeft: 10,
                    marginBottom: 10,
                    fontFamily: "proximanova_light",
                  }}
                >
                  {this.props.loginError}
                </div>
              )}
              {this.state.forgotPasswordClicked && (
                <p style={{ fontFamily: "proximanova_light" }}>
                  {this.state.forgotPasswordMessage}
                </p>
              )}
              {this.props.loading && (
                <div>
                  <p style={{ fontFamily: "proximanova_light" }}>
                    signing in...
                  </p>
                </div>
              )}

              <button
                className="login_button"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "24px",
                  backgroundColor: "black",
                  padding: "10px",
                  width: "190px",
                  letterSpacing: "2.5px",
                  marginBottom: "5px",
                }}
                type="Submit"
              >
                LOGIN
              </button>
            </div>
          </Form>
        )}
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => {
              this.forgotPasswordPressed();
            }}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <p
              style={{
                color: "black",
                fontFamily: proximanova_light,
              }}
            >
              FORGOT PASSWORD?
            </p>
          </button>
          <div style={{ width: "2rem" }}></div>
          <Link
            to={"/sign-up"}
            style={{
              color: "black",
              fontFamily: proximanova_light,
              textDecoration: "none",
              marginRight: "40px",
            }}
          >
            CREATE PROFILE
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    password: state.auth.password,
    loginError: state.auth.error,
    token: state.auth.token,
    loading: state.auth.loading,
    isLoggedIn: !!state.account.contactId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addToken,
    onASNameChange,
    onLIEmailChange,
    onLIPasswordChange,
    // onLIClearFields,
    onLIError,
    attemptLogin,
  })(Login)
);
