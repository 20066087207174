import {
  LOGIN_ATTEMPTED,
  LI_EMAIL_CHANGED,
  LI_PASSWORD_CHANGED,
  LI_TOKEN_CHANGED,
  LI_CLEARALL,
  LI_ERROR,
} from "./types";

export const attemptLogin = () => {
  return {
    type: LOGIN_ATTEMPTED,
  };
};

export const onLIEmailChange = (text) => {
  const lowerCaseText = text.toLowerCase();

  return {
    type: LI_EMAIL_CHANGED,
    payload: lowerCaseText,
  };
};

export const onLIPasswordChange = (text) => {
  return {
    type: LI_PASSWORD_CHANGED,
    payload: text,
  };
};

export const addToken = (text) => {
  return {
    type: LI_TOKEN_CHANGED,
    payload: text,
  };
};

export const onLIClearFields = (dispatch) => {
  return {
    type: LI_CLEARALL,
    payload: "clear all",
  };
};

export const onLIError = (err) => ({
  type: LI_ERROR,
  payload: err,
});
